export interface CreateAlternativeQuestionTextRequest {
    languageId: number;
    questionId: number;
    questionText: string;
}

export const createCreateAlternativeQuestionTextRequest = (
    questionId: number,
    languageId: number,
    questionText: string
): CreateAlternativeQuestionTextRequest => ({
    questionId: questionId,
    languageId: languageId,
    questionText: questionText,
});

import { createReactSelectDto, ReactSelectDto } from "../../domain/dtos/common/react-select-dto";
import ReviewRating from "../../domain/enums/review-reports/review-ratings";

export const ReviewRatingOptions: ReactSelectDto<ReviewRating>[] = [
    createReactSelectDto(0, "All"),
    createReactSelectDto(
        ReviewRating.AcceptableRoomForImprovement,
        "Acceptable Room For Improvement"
    ),
    createReactSelectDto(ReviewRating.Good, "Good"),
    createReactSelectDto(ReviewRating.NotRated, "Not Rated"),
    createReactSelectDto(ReviewRating.Unsatisfactory, "Unsatisfactory"),
    createReactSelectDto(
        ReviewRating.UnsatisfactoryRoomForImprovement,
        "Unsatisfactory Room For Improvement"
    ),
];

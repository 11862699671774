import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import {
    FaClone,
    FaEdit,
    FaLevelDownAlt,
    FaLevelUpAlt,
    FaLink,
    FaListAlt,
    FaPlusCircle,
    FaSort,
    FaThumbsDown,
    FaThumbsUp,
} from "react-icons/fa";
import { Link, Path } from "react-router-dom";
import styled, { css, ThemeContext } from "styled-components";
import { ChecklistQuestions, Common } from "../../core/constants/translation-namespace";

interface StyledLinkProps {
    readonly $variant: string;
}

const StyledLink = styled(Link)<StyledLinkProps>`
    text-decoration: none;

    &:hover {
        color: ${(props) => props.theme.palette.black};
    }

    ${({ $variant }) =>
        $variant === "primary" &&
        css`
            color: ${(props) => props.theme.palette.primary};
        `}
    ${({ $variant }) =>
        $variant === "secondary" &&
        css`
            color: ${(props) => props.theme.palette.secondary};
        `};
    ${({ $variant }) =>
        $variant === "warning" &&
        css`
            color: ${(props) => props.theme.palette.warning};
        `};
    ${({ $variant }) =>
        $variant === "danger" &&
        css`
            color: ${(props) => props.theme.palette.danger};
        `};
`;

interface NavigationProps<TType> {
    navigateTo: string | Partial<Path>;
    navigationProps?: TType | undefined;
}

interface NavigationAndLabelProps<TType> extends NavigationProps<TType> {
    label: string;
}

interface SbLinkProps<TType> extends NavigationProps<TType> {
    variant: "primary" | "secondary" | "warning" | "danger";
    label: string;
    icon?: IconType;
}

export const SbLink = <TType,>({
    variant,
    label,
    icon,
    navigateTo,
    navigationProps,
}: SbLinkProps<TType>): ReactElement<typeof Link> => {
    const themeContext = useContext(ThemeContext);

    return (
        <StyledLink $variant={variant} to={navigateTo} state={navigationProps}>
            {icon != undefined &&
                React.createElement(icon, {
                    size: themeContext.padding.xl,
                    style: { marginRight: themeContext.padding.sm },
                })}
            {label}
            {/* TODO: Add dimensions to global theme*/}
        </StyledLink>
    );
};

export const TextLink = <TType,>({
    label,
    navigateTo,
    navigationProps,
}: NavigationAndLabelProps<TType>): ReactElement<typeof Link> => (
    <SbLink
        variant="primary"
        label={label}
        navigateTo={navigateTo}
        navigationProps={navigationProps}
    />
);

export const ViewLink = <TType,>({
    label,
    navigateTo,
    navigationProps,
}: NavigationAndLabelProps<TType>): ReactElement<typeof Link> => (
    <SbLink
        variant="primary"
        label={label}
        icon={FaListAlt}
        navigateTo={navigateTo}
        navigationProps={navigationProps}
    />
);

export const CreateLink = <TType,>({
    label,
    navigateTo,
    navigationProps,
}: NavigationAndLabelProps<TType>): ReactElement<typeof Link> => (
    <SbLink
        variant="primary"
        label={label}
        icon={FaPlusCircle}
        navigateTo={navigateTo}
        navigationProps={navigationProps}
    />
);

export const EditLink = <TType,>({
    navigateTo,
    navigationProps,
}: NavigationProps<TType>): ReactElement<typeof Link> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbLink
            variant="primary"
            label={t("Edit")}
            icon={FaEdit}
            navigateTo={navigateTo}
            navigationProps={navigationProps}
        />
    );
};

export const PromoteLink = <TType,>({
    navigateTo,
    navigationProps,
}: NavigationProps<TType>): ReactElement<typeof Link> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbLink
            variant="primary"
            label={t("Promote")}
            icon={FaLevelUpAlt}
            navigateTo={navigateTo}
            navigationProps={navigationProps}
        />
    );
};

export const DemoteLink = <TType,>({
    navigateTo,
    navigationProps,
}: NavigationProps<TType>): ReactElement<typeof Link> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbLink
            variant="warning"
            label={t("Demote")}
            icon={FaLevelDownAlt}
            navigateTo={navigateTo}
            navigationProps={navigationProps}
        />
    );
};

export const ActivateLink = <TType,>({
    navigateTo,
    navigationProps,
}: NavigationProps<TType>): ReactElement<typeof Link> => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <SbLink
            variant="primary"
            label={t("Activate")}
            icon={FaThumbsUp}
            navigateTo={navigateTo}
            navigationProps={navigationProps}
        />
    );
};

export const SuspendLink = <TType,>({
    navigateTo,
    navigationProps,
}: NavigationProps<TType>): ReactElement<typeof Link> => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    return (
        <SbLink
            variant="warning"
            label={t("Suspend")}
            icon={FaThumbsDown}
            navigateTo={navigateTo}
            navigationProps={navigationProps}
        />
    );
};

export const OrderLink = <TType,>({
    label,
    navigateTo,
    navigationProps,
}: NavigationAndLabelProps<TType>): ReactElement<typeof Link> => (
    <SbLink
        variant="primary"
        label={label}
        icon={FaSort}
        navigateTo={navigateTo}
        navigationProps={navigationProps}
    />
);

export const AssociateLink = <TType,>({
    label,
    navigateTo,
    navigationProps,
}: NavigationAndLabelProps<TType>): ReactElement<typeof Link> => (
    <SbLink
        variant="primary"
        label={label}
        icon={FaLink}
        navigateTo={navigateTo}
        navigationProps={navigationProps}
    />
);

export const CloneLink = <TType,>({
    label,
    navigateTo,
    navigationProps,
}: NavigationAndLabelProps<TType>): ReactElement<typeof Link> => (
    <SbLink
        variant="primary"
        label={label}
        icon={FaClone}
        navigateTo={navigateTo}
        navigationProps={navigationProps}
    />
);

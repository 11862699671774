import { ReactElement } from "react";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NavbarDropdowns } from "../../core/constants/translation-namespace";
import { useAuth } from "../../core/store/auth-context";
import { getIcon } from "../../core/utilities/getIcon";
import { getNavbarDropdownItemLink } from "../../core/utilities/getNavbarDropdownItemLink";

const StyledCol = styled(Col)`
    height: 100%;
    padding: 0;
`;

const StyledText = styled.div`
    padding-left: ${(props) => props.theme.padding.sm};
`;

const StyledDropdown = styled(Dropdown)`
    height: 100%;
    width: 100%;
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
    border-radius: 0;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.primary};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    background-color: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette.white};

    &:focus {
        background-color: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.white};
        border-color: ${(props) => props.theme.palette.primary};
    }

    &:hover {
        background-color: ${(props) => props.theme.palette.purple};
        color: ${(props) => props.theme.palette.white};
        border-color: ${(props) => props.theme.palette.purple};
    }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
    padding-left: ${(props) => props.theme.padding.md};
    padding-right: ${(props) => props.theme.padding.md};
`;

const StyledDropdownItem = styled(Dropdown.Item)`
    color: ${(props) => props.theme.palette.primary};

    &:hover {
        background-color: ${(props) => props.theme.palette.purple};
        color: ${(props) => props.theme.palette.white};
        border-color: ${(props) => props.theme.palette.purple};
    }
`;

export const NavbarDropdown: React.FC<{
    hasIcon: boolean;
    text: string;
    items: string[];
    checkForLatestWizardNotification?: () => void;
}> = ({ hasIcon, text, items, checkForLatestWizardNotification }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const { t } = useTranslation("translation", { keyPrefix: NavbarDropdowns });

    return (
        <StyledCol sm="auto">
            <StyledDropdown>
                <StyledDropdownToggle id="dropdown-custom-1">
                    {hasIcon && getIcon(text)}
                    <StyledText>{text === auth.userName ? text : t(text)}</StyledText>
                </StyledDropdownToggle>
                <StyledDropdownMenu align="right">
                    {items.map((item, index, items) => (
                        <div
                            key={item + index}
                            onClick={() =>
                                getNavbarDropdownItemLink(
                                    item,
                                    auth,
                                    navigate,
                                    checkForLatestWizardNotification
                                )
                            }
                        >
                            <StyledDropdownItem eventKey={item}>{t(item)}</StyledDropdownItem>
                            {index + 1 < items.length && <Dropdown.Divider />}
                        </div>
                    ))}
                </StyledDropdownMenu>
            </StyledDropdown>
        </StyledCol>
    );
};

export interface DropdownItemProps<TType> {
    // TODO: Rename and dir refactor
    label?: string;
    items: TType[];
    displayItemText: (item: TType) => string;
    onItemClicked: (item: TType) => void;
    defaultValue?: string;
}

export const DropdownItem = <TType,>({
    label,
    items,
    displayItemText,
    onItemClicked,
}: DropdownItemProps<TType>): ReactElement => (
    <StyledCol sm="auto">
        <StyledDropdown>
            <StyledDropdownToggle>{label && <StyledText>{label}</StyledText>}</StyledDropdownToggle>
            <StyledDropdownMenu align="right">
                {items.map((item, index, items) => (
                    <div key={index} onClick={() => onItemClicked(item)}>
                        <StyledDropdownItem eventKey={displayItemText(item)}>
                            {displayItemText(item)}
                        </StyledDropdownItem>
                        {index + 1 < items.length && <Dropdown.Divider />}
                    </div>
                ))}
            </StyledDropdownMenu>
        </StyledDropdown>
    </StyledCol>
);

import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading, isQueryLoading } from "../../../core/utilities/responseStateHelper";
import { useEditReferentialLinkType } from "../../../domain/viewmodels/referential-link-types/edit-referential-link-type-viewmodel";
import { useGetReferentialLinkTypeDetails } from "../../../domain/viewmodels/referential-link-types/view-referential-link-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditReferentialLinkTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const referentialLinkTypeId = Number(useParams().referentialLinkTypeId);

    const editReferentialLinkType = useEditReferentialLinkType();
    const getReferentialLinkTypeDetails = useGetReferentialLinkTypeDetails(referentialLinkTypeId);

    const getReferentialLinkTypeDetailsData = getReferentialLinkTypeDetails.data;

    useLoader(
        isQueryLoading(getReferentialLinkTypeDetails) || isMutationLoading(editReferentialLinkType),
        EditReferentialLinkTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.ReferentialLinkTypes);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        editReferentialLinkType.mutate(
            {
                referentialLinkTypeId: referentialLinkTypeId,
                formData: formData,
            },
            {
                onSuccess: async () =>
                    navigate(
                        `${getPath(AccordionTitles.ReferentialLinkTypes)}/${referentialLinkTypeId}`
                    ),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("EditReferentialLinkType")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("EditReferentialLinkType")}>
                <Form onSubmit={handleSubmit}>
                    <SbFormTextFieldGroup
                        name="name"
                        label={t("Name")}
                        maxLength={50}
                        minLength={2}
                        type="text"
                        defaultValue={getReferentialLinkTypeDetailsData?.name}
                        required
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </Form>
            </TextTitledPanel>
        </>
    );
};

export default EditReferentialLinkTypeContainer;

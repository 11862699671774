import { HTTPError } from "ky";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { EdithecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { useEditChecklist } from "../../../domain/viewmodels/checklists/edit-checklist-viewmodel";
import { useGetChecklist } from "../../../domain/viewmodels/checklists/view-checklist-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditChecklistContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);

    const checklistDetails = useGetChecklist(checklistId);
    const editChecklist = useEditChecklist();

    useLoader(
        isQueryLoading(checklistDetails) || isMutationLoading(editChecklist),
        EditChecklistContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editChecklist.mutate(
            {
                checklistId: checklistId,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(auth.email, window.location.href, EdithecklistEvent);
                    navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}`);
                },

                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EdithecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    return (
        <>
            <PageHeading>{t("ChecklistEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(checklistDetails) && (
                <TextTitledPanel title={t("EditChecklist")}>
                    <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                        {checklistDetails.data!.isTraining && (
                            <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
                        )}

                        <SbFormTextFieldGroup
                            name="name"
                            label={`${t("Name")}`}
                            type="text"
                            maxLength={100}
                            required
                            defaultValue={checklistDetails.data!.name}
                        />

                        <SbFormTextAreaGroup
                            name="description"
                            label={`${t("Description", { keyPrefix: Common })}`}
                            rows={4}
                            maxLength={250}
                            defaultValue={checklistDetails.data!.description ?? ""}
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </StyledHiddenOverflowForm>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditChecklistContainer;

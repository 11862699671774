import React, { ChangeEventHandler } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { StyledFormLabel } from "../../../core/theme/global-styles";
import SbLabelText from "../../atoms/SbLabelText";

const SbFormikTextFieldGroup: React.FC<{
    name: string;
    label: string;
    type: string;
    defaultValue?: string | null;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    onFormikChange?: ChangeEventHandler<Element>;
    error?: string;
}> = ({
    name,
    label,
    type,
    defaultValue,
    value,
    required,
    disabled,
    children,
    onFormikChange,
    error,
}) => (
    <Form.Group as={Row} className="mb-3">
        <StyledFormLabel column sm={2}>
            <SbLabelText label={label} required={required} />
        </StyledFormLabel>
        <Col sm={4}>
            <Form.Control
                name={name}
                type={type}
                key={defaultValue}
                defaultValue={defaultValue ?? undefined}
                value={value}
                required={required}
                disabled={disabled}
                onChange={onFormikChange}
            />
            {error && <div>{error}</div>}
        </Col>
        {children && <Col>{children}</Col>}
    </Form.Group>
);

export default SbFormikTextFieldGroup;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { TableRow } from "../../../core/utilities/customTypes";
import {
    nestedQuestionColumnNames,
    questionsColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import {
    AnswerTypeSearchDto,
    defaultAnswerTypeSearchDto,
    defaultSearchDto,
    SearchDto,
} from "../../../domain/dtos/common/search-dto";
import { QuestionCategoryDto } from "../../../domain/dtos/question-categories/question-category-dto";
import {
    defaultFilterQuestionsDto,
    FilterQuestionsDto,
} from "../../../domain/dtos/questions/filter-questions-dto";
import { QuestionTypes } from "../../../domain/enums/questions/question-types";
import { hasRoleTypeInGroup, QuestionRoleGroup } from "../../../domain/enums/Roles";
import {
    useLookupAnswerTypes,
    useLookupQuestionCategories,
} from "../../../domain/viewmodels/questions/view-question-dropdown-values-viewmodel";
import { useGetQuestions } from "../../../domain/viewmodels/questions/view-questions-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { QuestionFilter } from "../../organisms/filters/QuestionFilter";

const questionTypesArray = getEnumsForType(QuestionTypes).map((x) =>
    createReactSelectDto(x, QuestionTypes[x])
);

interface SearchParams {
    questionId: number | null;
    questionSetName: string | null;
    questionText: string | null;
    questionTypeId: number | null;
    questionCategoryId: number | null;
    answerTypeId: number | null;
}

const createSearchParams = (
    questionId: number | null,
    questionSetName: string | null,
    questionText: string | null,
    questionTypeId: number | null,
    questionCategoryId: number | null,
    answerTypeId: number | null
): SearchParams => ({
    questionId: questionId,
    questionSetName: questionSetName,
    questionText: questionText,
    questionTypeId: questionTypeId,
    questionCategoryId: questionCategoryId,
    answerTypeId: answerTypeId,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null, null);

const QuestionsContainer = (): JSX.Element => {
    const [filterDto, setFilterDto] = useState<FilterQuestionsDto>(defaultFilterQuestionsDto);
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [, setRows] = useState<TableRow<number>[]>([]);
    const [searchDto, setSearchDto] = useState<SearchDto>(defaultSearchDto);
    const [answerTypeSearchDto, setAnswerTypeSearchDto] = useState<AnswerTypeSearchDto>(
        defaultAnswerTypeSearchDto
    );

    const menu = useMenu();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();

    const getQuestions = useGetQuestions(filterDto);

    const lookupQuestionCategories = useLookupQuestionCategories(searchDto);
    const lookupQuestionCategoriesData = lookupQuestionCategories.data!;
    const lookupAnswerTypes = useLookupAnswerTypes(answerTypeSearchDto);
    const lookupAnswerTypesData = lookupAnswerTypes.data!;

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(getQuestions), QuestionsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useEffect(() => {
        if (getQuestions.fetchStatus === "idle" && getQuestions.status === "success") {
            setRows(getQuestions.data!.rows);
        }
    }, [getQuestions.fetchStatus, getQuestions.status]);

    const changeQuestionId = (questionId: string): void => {
        setSearchParams({
            ...searchParams,
            questionId: Number(questionId),
        });
    };

    const changeQuestionSetName = (questionSetName: string): void => {
        setSearchParams({
            ...searchParams,
            questionSetName: questionSetName,
        });
    };

    const changeQuestionText = (questionText: string): void => {
        setSearchParams({
            ...searchParams,
            questionText: questionText,
        });
    };

    const changeQuestionType = (option: SingleValue<ReactSelectDto<QuestionTypes>>): void => {
        setSearchParams({
            ...searchParams,
            answerTypeId:
                option?.value != searchParams.questionTypeId && option?.value != null
                    ? null
                    : searchParams.answerTypeId,
            questionTypeId: option?.value ?? null,
        });
        setAnswerTypeSearchDto({
            ...answerTypeSearchDto,
            questionTypeId: option?.value ?? null,
        });
    };

    const changeQuestionCategory = (option: SingleValue<QuestionCategoryDto>): void => {
        setSearchParams({
            ...searchParams,
            questionCategoryId: option?.questionCategoryId ?? null,
        });
    };

    const changeAnswerType = (option: SingleValue<AnswerTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            answerTypeId: option?.answerTypeId ?? null,
        });
    };

    const search = (): void => {
        setFilterDto({
            ...filterDto,
            pageNumber: 1,
            ...searchParams,
        });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);

        setFilterDto({ ...filterDto, ...defaultSearchParams });
        setSearchDto(defaultSearchDto);
        setAnswerTypeSearchDto(defaultAnswerTypeSearchDto);
    };

    const navigateToViewQuestion = (questionId: number): void =>
        navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`);

    const navigateToEditQuestion = (questionId: number): void => {
        navigate(`${getPath(AccordionTitles.Questions)}/${questionId}/edit`);
    };

    const navigateToDeleteQuestion = (questionId: number): void =>
        navigate(`${getPath(AccordionTitles.Questions)}/${questionId}/delete`);

    const navigateToEditNestedQuestionTrigger = (nestedQuestionId: number): void => {
        const params = [
            createNavigateSearchParameter("nestedQuestionId", nestedQuestionId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Questions)}/update-nested-question-trigger-selection`,
            params
        );
    };

    const navigateToNestedQuestion = (nestedQuestionId: number): void => {
        let nestedRow: TableRow<number> | undefined;

        getQuestions!.data?.rows.find(
            (x) => (nestedRow = x.nestedRows?.find((n) => n.metadata === nestedQuestionId))
        );

        const questionId = nestedRow?.columns.find((x) => x.metadata === "ID")?.value;
        if (nestedRow && questionId) {
            navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`);
        }
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("QuestionsTitle")}</PageHeading>
            <PageSubHeading>{t("QuestionsHeaderHelperText")}</PageSubHeading>
            <SectionVerticalSpace />
            <QuestionFilter
                questionId={searchParams.questionId}
                changeQuestionId={changeQuestionId}
                questionSetName={searchParams.questionSetName}
                changeQuestionSetName={changeQuestionSetName}
                questionText={searchParams.questionText}
                changeQuestionText={changeQuestionText}
                questionType={createReactSelectDto(
                    searchParams.questionTypeId!,
                    QuestionTypes[searchParams.questionTypeId!]
                )}
                questionTypes={questionTypesArray}
                changeQuestionType={changeQuestionType}
                questionCategories={lookupQuestionCategoriesData}
                changeQuestionCategory={changeQuestionCategory}
                answerTypes={lookupAnswerTypesData}
                changeAnswerType={changeAnswerType}
                search={search}
                resetFilter={resetFilter}
                areQuestionCategoriesLoading={isQueryLoading(lookupQuestionCategories)}
                setQuestionCategorySearchText={setSearchDto}
                areAnswerTypesLoading={isQueryLoading(lookupAnswerTypes)}
                setAnswerTypeSearchText={setAnswerTypeSearchDto}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(AccordionTitles.Questions)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />
            {isQuerySuccessful(getQuestions) && (
                <DataTable
                    columns={questionsColumnNames}
                    rows={getQuestions.data!.rows}
                    viewItem={navigateToViewQuestion}
                    editItem={
                        hasRoleTypeInGroup(auth.userRoles, QuestionRoleGroup.WriteRoles)
                            ? navigateToEditQuestion
                            : undefined
                    }
                    keyPrefix={ChecklistQuestions}
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, QuestionRoleGroup.WriteRoles)
                            ? navigateToDeleteQuestion
                            : undefined
                    }
                    totalItems={getQuestions.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                    nestedColumnNames={nestedQuestionColumnNames}
                    deleteNestedItem={(nestedQuestionId: number): void => {
                        navigate(
                            `${getPath(
                                AccordionTitles.Questions
                            )}/${nestedQuestionId}/dissociate-nested-question`
                        );
                    }}
                    linkNestedItem={navigateToNestedQuestion}
                    editNestedItem={navigateToEditNestedQuestionTrigger}
                    setRows={setRows}
                />
            )}
        </>
    );
};

export default QuestionsContainer;

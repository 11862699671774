import React from "react";
import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { useWizardNotification } from "../../core/store/wizard-notification-context";
import { LargeVerticalSpace, StyledBoldSpan, StyledSpan } from "../../core/theme/global-styles";
import { NotificationStatuses } from "../../core/utilities/enums";
import { getNotificationStatus } from "../../core/utilities/getNotificationStatus";
import { WizardCardDto } from "../../domain/dtos/wizards/wizard-card-dto";
import { WizardNotificationHistoryDto } from "../../domain/dtos/wizards/wizard-notification-history-dto";
import { SbButton } from "../atoms/SbButton";
import { SbNotificationStatusDot } from "../atoms/SbNotificationStatusDot";

const StyledCard = styled(Card)`
    width: 300px;
    padding-top: ${(props) => props.theme.padding.lg};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    margin-left: ${(props) => props.theme.padding.lg};
    margin-bottom: ${(props) => props.theme.padding.lg};
    font-color: ${(props) => props.theme.palette.black};
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};
`;

const StyledCardBody = styled(Card.Body)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledCardImg = styled(Card.Img)``;

export const WizardNotificationHistoryGallery: React.FC<{
    wizardNotifications: WizardNotificationHistoryDto[];
}> = ({ wizardNotifications }) => {
    const wizardNotification = useWizardNotification();

    const onNotificationOpen = (wizardCards: WizardCardDto[], notificationStatus: number): void => {
        wizardNotification.onWizardNotificationStatusChange(notificationStatus);
        wizardNotification.onWizardCardsFetch(wizardCards);
        wizardNotification.updateShow(wizardCards.length > 0);
    };

    return (
        <Row xs={2} md={5}>
            {wizardNotifications &&
                wizardNotifications.map((wizardNotification, index) => (
                    <StyledCard key={index} id={wizardNotification.wizardCards[0]?.fileDetailsId}>
                        <SbNotificationStatusDot
                            variant={
                                wizardNotification.notificationStatus ===
                                NotificationStatuses.Finished
                                    ? "success"
                                    : "danger"
                            }
                        />
                        <StyledCardImg
                            src={wizardNotification.wizardCards[0]?.fileImagePath}
                            alt="Image Not Available"
                            width="25%"
                            height="70%"
                        />
                        <StyledCardBody>
                            <StyledBoldSpan>{wizardNotification.wizardName}</StyledBoldSpan>
                            <StyledSpan>{wizardNotification.dateCreatedLocal}</StyledSpan>
                            <StyledSpan>
                                {getNotificationStatus(wizardNotification.notificationStatus)}
                            </StyledSpan>
                            <LargeVerticalSpace />
                            <SbButton
                                variant="primary"
                                label="OPEN"
                                type={"button"}
                                onClick={() =>
                                    onNotificationOpen(
                                        wizardNotification.wizardCards,
                                        wizardNotification.notificationStatus
                                    )
                                }
                            />
                        </StyledCardBody>
                    </StyledCard>
                ))}
        </Row>
    );
};

import React, { useContext } from "react";
import { FaUser } from "react-icons/fa";
import styled, { ThemeContext } from "styled-components";
import { toTimeFormat } from "../../../core/utilities/date-helper";

const StyledContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => props.theme.padding.xxs};
`;

const StyledTitleDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledRaciParticipationDiv = styled.div`
    display: flex;
    margin-left: ${(props) => props.theme.padding.sm};
    margin-right: ${(props) => props.theme.padding.sm};
    align-items: center;
`;

const InlineActionItemEvent: React.FC<{
    startDateLocal: Date;
    title: string;
    raciParticipation: string;
}> = ({ startDateLocal, title, raciParticipation }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <StyledContainerDiv>
            <StyledTitleDiv>
                <b>{toTimeFormat(startDateLocal.toString())}</b> {title}
            </StyledTitleDiv>
            <StyledRaciParticipationDiv>
                <FaUser style={{ marginRight: themeContext.padding.sm }} /> {raciParticipation}
            </StyledRaciParticipationDiv>
        </StyledContainerDiv>
    );
};

export default InlineActionItemEvent;

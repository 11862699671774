import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled, { ThemeContext } from "styled-components";
import {
    ActionItems,
    ChecklistAnswers,
    Common,
} from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { dataDropdownItems } from "../../../core/utilities/filterDropdownItems";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { AnswerSummaryType } from "../../../domain/enums/answer-summary/AnswerSummaryType";
import { useGetTeamAnswerSummary } from "../../../domain/viewmodels/answer-summary/answer-summary-viewmodel";
import { FilterSelect } from "../../molecules/FilterSelect";
import { AnswerSummaryCardGallery } from "../../organisms/AnswerSummaryCardGallery";
import Legend from "../../organisms/legend";

export const StartAlignedDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 30%;
`;

const TeamAnswerSummaryContainer: React.FC = () => {
    const [dataSelection, setDataSelection] = useState<boolean | null>(null);
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const themeContext = useContext(ThemeContext);

    const getTeamAnswerSummary = useGetTeamAnswerSummary(dataSelection);

    useLoader(isQueryLoading(getTeamAnswerSummary), TeamAnswerSummaryContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.TeamAnswerSummary);
    }, []);

    const onChangeData = (option: SingleValue<ReactSelectDto<string>>): void => {
        setDataSelection(
            option?.value === "Training" ? true : option?.value === "Live" ? false : null
        );
    };

    const buildLegend = (): ReactElement<HTMLDivElement> => (
        <Legend
            labelColourMap={
                new Map([
                    [t("Completed"), themeContext.palette.instanceStatus.completed],
                    [t("Overdue"), themeContext.palette.instanceStatus.overdue],
                    [t("Due"), themeContext.palette.instanceStatus.due],
                    [t("NotDue"), themeContext.palette.instanceStatus.notDue],
                ])
            }
        />
    );

    return (
        <>
            <PageHeading>{t("TeamAnswerSummary", { keyPrefix: ChecklistAnswers })}</PageHeading>
            <PageSubHeading>
                {t("HeaderHelpTextTeamAnswerSummary", { keyPrefix: ChecklistAnswers })}
            </PageSubHeading>
            <SectionVerticalSpace />

            <StartAlignedDiv>
                <FilterSelect
                    name={"data"}
                    searchable
                    clearable
                    items={valuesToReactSelectDtos(dataDropdownItems)}
                    itemDisplayText={(option: ReactSelectDto<string>) =>
                        t(option.label, { keyPrefix: Common })
                    }
                    defaultSelectedItem={valuesToReactSelectDtos(dataDropdownItems).find(
                        (x) => x.label === "All"
                    )}
                    onChange={onChangeData}
                    placeholderText={`${t("All", { keyPrefix: Common })}`}
                />
            </StartAlignedDiv>
            <EndAlignedDiv>{buildLegend()}</EndAlignedDiv>
            <SectionVerticalSpace />

            {isQuerySuccessful(getTeamAnswerSummary) && (
                <AnswerSummaryCardGallery
                    answerSummaryCards={getTeamAnswerSummary.data}
                    answerSummaryType={AnswerSummaryType.Team}
                />
            )}
        </>
    );
};

export default TeamAnswerSummaryContainer;

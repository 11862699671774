import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateChecklistEvent } from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateChecklist } from "../../../domain/viewmodels/checklists/create-checklist-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbRibbon } from "../../atoms/SbRibbon";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateChecklistContainer: React.FC = () => {
    const [isTraining, setIsTraining] = useState(false);

    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const createChecklist = useCreateChecklist();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const handleIsTrainingChange = (_: string, checked: boolean): void => {
        setIsTraining(checked);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createChecklist.mutate(
            {
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async (response: Response<number>) => {
                    trackAppInsightsEvent(auth.email, window.location.href, CreateChecklistEvent);
                    navigate(`${getPath(AccordionTitles.Checklists)}/${response.data}`);
                },

                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        CreateChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    return (
        <>
            <PageHeading>{t("ChecklistCreateTitle")}</PageHeading>
            <PageSubHeading>{t("ChecklistCreateHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateChecklist")}>
                <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                    {isTraining && (
                        <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
                    )}

                    <SbFormTextFieldGroup
                        name="name"
                        label={t("Name")}
                        maxLength={100}
                        type="text"
                        required
                    />

                    <SbFormTextAreaGroup
                        name="description"
                        label={t("Description", { keyPrefix: Common })}
                        rows={4}
                        maxLength={250}
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={`${t("ChecklistAssignmentIsTrainingDisplay")}?`}
                        type="checkbox"
                        values={[
                            {
                                name: "isTraining",
                                onChangeHandler: handleIsTrainingChange,
                            },
                        ]}
                    />

                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </StyledHiddenOverflowForm>
            </TextTitledPanel>
        </>
    );
};

export default CreateChecklistContainer;

import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    ChecklistQuestions,
    Common,
    Languages,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { alternativeQuestionsTextsColumnNamesAlt } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { LanguageDto } from "../../../domain/dtos/language/language-dto";
import { emptySearchQuestionAssociationRequest } from "../../../domain/requests/questions/search-question-association-request";
import {
    createCreateParameters,
    useCreateAlternativeQuestionText,
} from "../../../domain/viewmodels/questions/create-alternative-question-text-viewmodel";
import { useGetAlternativeQuestionTexts } from "../../../domain/viewmodels/questions/view-alternative-question-texts-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";

const CreateAlternativeQuestionTextContainer: React.FC = () => {
    const [alternativeQuestionTextPaginationDto, setAlternativeQuestionTextPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const languageId = useRef<number | null>(null);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const questionId = Number(useParams().questionId);
    const searchRequest = {
        ...emptySearchQuestionAssociationRequest(),
        questionId: questionId,
    };

    const alternativeQuestionTexts = useGetAlternativeQuestionTexts(
        alternativeQuestionTextPaginationDto,
        searchRequest
    );
    const alternativeQuestionTextsResponseData = alternativeQuestionTexts[0].data;
    const languagesResponseData = alternativeQuestionTexts[1].data;

    const createAlternativeQuestionText = useCreateAlternativeQuestionText();

    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    useLoader(
        areQueriesLoading(alternativeQuestionTexts) ||
            isMutationLoading(createAlternativeQuestionText),
        CreateAlternativeQuestionTextContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        createAlternativeQuestionText.mutate(
            createCreateParameters(
                questionId,
                languageId.current! || -1,
                new FormData(event.currentTarget)
            ),
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getAlternativeQuestionTexts"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "AddAlternativeQuestionTextSuccessMessage"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const renderAlternativeQuestionTexts = (): JSX.Element => (
        <SbAccordion title={t("AlternativeQuestionTexts", { keyPrefix: ChecklistQuestions })}>
            <DataTable
                noResultsMessage={
                    t("QuestionsNoAlternativeFound", { keyPrefix: ChecklistQuestions })!
                }
                columns={alternativeQuestionsTextsColumnNamesAlt}
                rows={alternativeQuestionTextsResponseData!.rows}
                totalItems={alternativeQuestionTextsResponseData!.recordCount}
                paginationDto={alternativeQuestionTextPaginationDto}
                setPaginationDto={setAlternativeQuestionTextPaginationDto}
            />
        </SbAccordion>
    );

    const renderAddAlternativeQuestionText = (): JSX.Element => (
        <Form onSubmit={handleSubmit}>
            <SbAccordion title={t("AddAlternativeQuestionText", { keyPrefix: ChecklistQuestions })}>
                <SbFormSelectFieldGroup
                    name={"language"}
                    label={t("Language", { keyPrefix: Languages })}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable={false}
                    required
                    items={languagesResponseData!}
                    itemDisplayText={(option: LanguageDto) => option.name} //TODO: Add translations for dynamic data
                    onChange={(option: SingleValue<LanguageDto>) => {
                        languageId.current = option!.languageId;
                    }}
                />

                <SbFormTextAreaGroup
                    name={"questionText"}
                    label={t("QuestionText", { keyPrefix: ChecklistQuestions })!}
                    required
                    rows={4}
                    maxLength={4000}
                />

                <EndAlignedDiv>
                    <SaveButton type="submit" label={t("Add", { keyPrefix: Common })!} />
                    <CancelButton onClick={() => navigate(-1)} />
                </EndAlignedDiv>
            </SbAccordion>
        </Form>
    );

    return (
        <>
            {areQueriesSuccessful(alternativeQuestionTexts) && (
                <>
                    <PageHeading>
                        {t("AddAlternativeQuestionText", { keyPrefix: ChecklistQuestions })}
                    </PageHeading>
                    <PageSubHeading>
                        {t("HeaderHelpTextAlternativeQuestionText", {
                            keyPrefix: ChecklistQuestions,
                        })}
                    </PageSubHeading>
                    <SectionVerticalSpace />

                    {renderAlternativeQuestionTexts()}
                    <SectionVerticalSpace />

                    {renderAddAlternativeQuestionText()}
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );
};

export default CreateAlternativeQuestionTextContainer;

import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createAlternativeQuestionText } from "../../../data/services/questions/question-service";
import { createCreateAlternativeQuestionTextRequest } from "../../requests/questions/create-alternative-question-text-request";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    questionId: number;
    languageId: number;
    formData: FormData;
}

export const createCreateParameters = (
    questionId: number,
    languageId: number,
    formData: FormData
): CreateParameters => ({
    questionId: questionId,
    languageId: languageId,
    formData: formData,
});

export const useCreateAlternativeQuestionText = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const { questionId, languageId, formData } = mutationKey;

        const request = createCreateAlternativeQuestionTextRequest(
            questionId,
            languageId,
            formData.get("questionText") as string
        );

        return createAlternativeQuestionText(url.baseUrl, request);
    });
};

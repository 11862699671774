import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    editQuestionCategory,
    getQuestionCategoryDetails,
} from "../../../data/services/question-categories/question-category-service";
import { toQuestionCategoryDto } from "../../dtos/question-categories/question-category-dto";
import { Response } from "../../responses/common/response-response";
import { QuestionCategoryResponse } from "../../responses/question-category/question-category-response";

interface EditParameters {
    questionCategoryId: number;
    formData: FormData;
}

export const useGetQuestionCategoryDetails = (
    questionCategoryId: number
): UseQueryResult<QuestionCategoryResponse, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getQuestionCategoryDetails", questionCategoryId],
        () => getQuestionCategoryDetails(url.baseUrl, questionCategoryId),
        {
            select: toQuestionCategoryDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useEditQuestionCategory = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            questionCategoryId: mutationKey.questionCategoryId,
            questionCategoryName: formData.get("name")! as string,
        };

        return editQuestionCategory(url.baseUrl, request);
    });
};

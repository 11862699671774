import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { createReferentialLink } from "../../../data/services/referential-link-types/referential-link-service";
import { createCreateReferentialLinkRequest } from "../../requests/referential-link-types/create-referential-link-request";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    referentialLinkTypeId: number;
    formData: FormData;
}

export const useCreateReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const { formData, referentialLinkTypeId } = mutationKey;

        const request = createCreateReferentialLinkRequest(
            referentialLinkTypeId,
            formData.get("referentialLinkValue")! as string
        );

        return createReferentialLink(url.baseUrl, request);
    });
};

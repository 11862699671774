import hash from "object-hash";
import { ReactElement, useContext, useState } from "react";
import Select, { components, GroupBase, NoticeProps, OptionProps, SingleValue } from "react-select";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { DefaultTheme, ThemeContext } from "styled-components";

export interface SbSelectProps<TType> {
    styles?: any;
    name: string;
    placeholderText?: string;
    searchable: boolean;
    clearable: boolean;
    loading?: boolean;
    disabled?: boolean;
    items: TType[] | undefined;
    defaultSelectedItem?: TType | null;
    itemLabel: (item: TType) => string;
    itemValue?: (item: TType) => string;
    onChange?: (item: SingleValue<TType>) => void;
    value?: TType | null;
    onSearchTextChanged?: (searchString: string) => void;
    itemTooltip?: (item: TType) => string | null;
    required?: boolean;
}

interface CreateOnNoResultsSelectProps<TType> extends SbSelectProps<TType> {
    createNewValue: (_: string) => TType;
}

export const SbSelect = <TType,>({
    styles,
    name,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    items,
    defaultSelectedItem,
    itemLabel,
    itemValue,
    onChange,
    value,
    onSearchTextChanged,
}: SbSelectProps<TType>): ReactElement => (
    <Select
        key={defaultSelectedItem ? hash.MD5(JSON.stringify(defaultSelectedItem)) : undefined}
        styles={styles}
        menuPosition="fixed"
        name={name}
        placeholder={placeholderText}
        isSearchable={searchable}
        noOptionsMessage={() => "No results"}
        isClearable={clearable}
        isLoading={loading}
        isDisabled={disabled}
        options={items}
        defaultValue={defaultSelectedItem}
        onChange={onChange}
        onInputChange={onSearchTextChanged}
        getOptionLabel={itemLabel}
        getOptionValue={itemValue !== undefined ? itemValue : itemLabel}
        value={value}
    />
);

const msgStyles = (themeContext: DefaultTheme): {} => ({
    color: themeContext.palette.primary,
    cursor: "pointer",

    "&:hover": {
        backgroundColor: themeContext.palette.primaryVariant,
        color: themeContext.palette.purewhite,
    },
});

const NoOptionsMessage = <TType,>({
    props,
    onCreateNewSelected,
}: {
    props: NoticeProps<TType, false, GroupBase<TType>>;
    onCreateNewSelected: () => void;
}): JSX.Element => {
    return (
        <div
            onClick={() => {
                onCreateNewSelected();
            }}
        >
            <components.NoOptionsMessage {...props} />
        </div>
    );
};

export const CreateOnNoResultsSelect = <TType,>({
    styles,
    name,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    items,
    defaultSelectedItem,
    itemLabel,
    itemValue,
    onChange,
    value,
    onSearchTextChanged,
    required,
    createNewValue,
}: CreateOnNoResultsSelectProps<TType>): ReactElement => {
    const [newValue, setNewValue] = useState<TType>();
    const [inputSearch, setInputSearch] = useState<string>();
    const [open, setOpen] = useState(false);
    const [isValueMissing, setIsValueMissing] = useState<boolean>(
        defaultSelectedItem ? false : true
    );

    const themeContext = useContext(ThemeContext);

    const onInputChange = (searchString: string): void => {
        setInputSearch(searchString);

        onSearchTextChanged && onSearchTextChanged(searchString);
    };

    const onOptionSelected = (item: SingleValue<TType>): void => {
        setNewValue(undefined);

        onChange && onChange(item);
        setIsValueMissing(false);
    };

    const onCreateNewSelected = (): void => {
        const newValue = createNewValue(inputSearch!);
        setNewValue(newValue);
        onChange && onChange(newValue);
        setOpen(false);
        setIsValueMissing(false);
    };

    return (
        <>
            <Select
                key={
                    defaultSelectedItem ? hash.MD5(JSON.stringify(defaultSelectedItem)) : undefined
                }
                menuPosition="fixed"
                name={name}
                placeholder={placeholderText}
                isSearchable={searchable}
                noOptionsMessage={() => "Add new value"}
                isClearable={clearable}
                isLoading={loading}
                isDisabled={disabled}
                options={items}
                defaultValue={defaultSelectedItem}
                onChange={onOptionSelected}
                onInputChange={onInputChange}
                getOptionLabel={itemLabel}
                getOptionValue={itemValue !== undefined ? itemValue : itemLabel}
                value={newValue ?? value}
                menuIsOpen={open}
                onMenuOpen={() => setOpen(true)}
                onMenuClose={() => setOpen(false)}
                components={{
                    NoOptionsMessage: (optionProps) => (
                        <NoOptionsMessage
                            props={optionProps}
                            onCreateNewSelected={onCreateNewSelected}
                        />
                    ),
                }}
                styles={
                    styles || {
                        noOptionsMessage: (base, _) => ({ ...base, ...msgStyles(themeContext) }),
                    }
                }
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                required={required}
                value={isValueMissing ? undefined : "value"}
            />
        </>
    );
};

const getFormattedTooltip = (text: string | null): string | null => {
    const element = text?.replace(/\n/g, " <br />");
    return element ? element?.toString() : null;
};

const tooltipStyle = (themeContext: DefaultTheme): {} => ({
    backgroundColor: themeContext.palette.primary,
    color: themeContext.palette.white,
    "border-radius": "0.375rem",
});

const Option = <TType,>({
    props,
    itemTooltip,
}: {
    props: OptionProps<TType, false, GroupBase<TType>>;
    itemTooltip?: (item: TType) => string | null;
}): JSX.Element => {
    const themeContext = useContext(ThemeContext);

    return (
        <>
            <div
                className="item"
                data-tooltip-id="my-tooltip"
                data-html="true"
                data-tooltip-html={
                    itemTooltip ? getFormattedTooltip(itemTooltip(props.data)) : null
                }
            >
                <components.Option {...props}>{props.children}</components.Option>
            </div>
            <Tooltip id="my-tooltip" style={tooltipStyle(themeContext)} place="left" />
        </>
    );
};

export const CreateTooltipSelect = <TType,>({
    styles,
    name,
    placeholderText,
    searchable,
    clearable,
    loading,
    disabled,
    items,
    defaultSelectedItem,
    itemLabel,
    itemValue,
    onChange,
    value,
    onSearchTextChanged,
    itemTooltip,
}: SbSelectProps<TType>): ReactElement => {
    return (
        <Select
            key={defaultSelectedItem ? hash.MD5(JSON.stringify(defaultSelectedItem)) : undefined}
            styles={styles}
            menuPosition="fixed"
            name={name}
            placeholder={placeholderText}
            isSearchable={searchable}
            noOptionsMessage={() => "No results"}
            isClearable={clearable}
            isLoading={loading}
            isDisabled={disabled}
            options={items}
            defaultValue={defaultSelectedItem}
            onChange={onChange}
            onInputChange={onSearchTextChanged}
            getOptionLabel={itemLabel}
            getOptionValue={itemValue !== undefined ? itemValue : itemLabel}
            value={value}
            components={{
                Option: (optionProps) => (
                    <Option
                        props={optionProps}
                        itemTooltip={(option) => (itemTooltip ? itemTooltip(option) : null)}
                    />
                ),
            }}
        />
    );
};

import React, { ReactElement, useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import {
    FaCheck,
    FaCheckSquare,
    FaClone,
    FaComment,
    FaFlag,
    FaInfoCircle,
    FaLevelDownAlt,
    FaLevelUpAlt,
    FaLink,
    FaPlus,
    FaRedo,
    FaSave,
    FaUnlink,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { RiDeleteBinFill } from "react-icons/ri";
import { VscChromeClose, VscTriangleLeft } from "react-icons/vsc";
import styled, { css, ThemeContext } from "styled-components";
import { ActionItems, ChecklistAnswers, Common } from "../../core/constants/translation-namespace";

interface StyledButtonProps {
    $hoverVariant: string;
    $alignItemsCenter: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>`
    display: flex;
    justify-content: center;
    gap: ${(props) => props.theme.padding.md};

    ${({ $alignItemsCenter }) =>
        $alignItemsCenter &&
        css`
            align-items: center;
        `}

    ${({ color }) =>
        color === "primary" &&
        css`
            color: ${(props) => props.theme.palette.primary};

            &:hover,
            &:focus {
                border-color: transparent;
                color: ${(props) => props.theme.palette.primary};
            }
        `}
  ${({ color }) =>
        color === "black" &&
        css`
            color: ${(props) => props.theme.palette.black};

            &:hover,
            &:focus {
                border-color: transparent;
                color: ${(props) => props.theme.palette.black};
            }
        `}
  ${({ color }) =>
        !color &&
        css`
            color: ${(props) => props.theme.palette.white};

            &:hover,
            &:focus {
                border-color: transparent;
                color: ${(props) => props.theme.palette.white};
            }
        `}


  ${({ variant }) =>
        variant === "primary" &&
        css`
            background-color: ${(props) => props.theme.palette.primary};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.primary};
            }
        `}
  ${({ variant }) =>
        variant === "primary-transparent" &&
        css`
            color: ${(props) => props.theme.palette.primary};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.purewhite};
                color: ${(props) => props.theme.palette.primary};
            }
        `}
  ${({ variant }) =>
        variant === "secondary" &&
        css`
            background-color: ${(props) => props.theme.palette.secondary};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.secondary};
            }
        `};
    ${({ variant }) =>
        variant === "secondary-transparent" &&
        css`
            color: ${(props) => props.theme.palette.secondary};
            border-color: ${(props) => props.theme.palette.grey};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.purewhite};
            }
        `};
    ${({ variant }) =>
        variant === "secondary-white" &&
        css`
            color: ${(props) => props.theme.palette.secondary};
            border-color: ${(props) => props.theme.palette.grey};
            background-color: ${(props) => props.theme.palette.purewhite};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.purewhite};
            }
        `};
    ${({ variant }) =>
        variant === "warning" &&
        css`
            background-color: ${(props) => props.theme.palette.warning};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.warning};
            }
        `};
    ${({ variant }) =>
        variant === "danger" &&
        css`
            background-color: ${(props) => props.theme.palette.danger};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.danger};
            }
        `};
    ${({ variant }) =>
        variant === "info" &&
        css`
            background-color: ${(props) => props.theme.palette.info};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.info};
            }
        `};
    ${({ variant }) =>
        variant === "advance" &&
        css`
            background-color: ${(props) => props.theme.palette.advance};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.advance};
            }
        `};
    ${({ variant }) =>
        variant === "success" &&
        css`
            background-color: ${(props) => props.theme.palette.success};

            &:hover,
            &:focus {
                background-color: ${(props) => props.theme.palette.success};
            }
        `};
    ${({ variant }) =>
        variant === "transparent" &&
        css`
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        `};

    ${({ $hoverVariant }) =>
        $hoverVariant === "border" &&
        css`
            &:hover {
                border-color: ${(props) => props.theme.palette.black};
            }
        `}
    ${({ $hoverVariant }) =>
        !$hoverVariant &&
        css`
            &:hover {
                background-color: ${(props) => props.theme.palette.purple};
            }
        `}
  ${({ $hoverVariant }) =>
        $hoverVariant === "none" &&
        css`
            &:hover {
                color: ${(props) => props.theme.palette.primary};
            }
        `}
  &:disabled {
        background-color: transparent;
        border-color: black;
        color: black;
    }
`;

export const SbButton: React.FC<{
    variant:
        | "primary"
        | "primary-transparent"
        | "secondary"
        | "secondary-transparent"
        | "secondary-white"
        | "warning"
        | "danger"
        | "info"
        | "advance"
        | "success"
        | "transparent";
    color?: "primary" | "black";
    hoverVariant?: "border" | "none";
    type: "submit" | "reset" | "button" | null;
    label: string;
    icon?: IconType;
    disabled?: boolean;
    onClick?: () => void;
    alignItemsCenter?: boolean;
    iconSize?: "sm" | "lg" | "xl" | null;
    iconPosition?: "left" | "right";
}> = ({
    variant,
    color,
    hoverVariant,
    type,
    label,
    icon,
    disabled,
    onClick,
    alignItemsCenter,
    iconSize,
    iconPosition = "left",
}): ReactElement<typeof Button> => (
    <StyledButton
        variant={variant}
        color={color}
        $hoverVariant={hoverVariant}
        $alignItemsCenter={alignItemsCenter ?? true}
        type={type}
        onClick={onClick}
        disabled={disabled}
    >
        {buildLabel(icon, label, iconSize, iconPosition)}
    </StyledButton>
);

export const CreateIconButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    label: string;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, label, onClick, disabled }): ReactElement<typeof Button> => (
    <SbButton
        variant={"primary"}
        type={type}
        label={label}
        icon={FaPlus}
        onClick={onClick}
        disabled={disabled}
    />
);

export const SaveButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, label, disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type={type}
            label={label ?? t("Save")}
            icon={FaSave}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const RemoveButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, label, disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="danger"
            type={type}
            label={label ?? t("Remove")}
            icon={FaXmark}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const ResolveButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, label, disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="success"
            type={type}
            label={label ?? t("Resolve")}
            icon={FaCheck}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const AcceptButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type={type}
            label={t("Accept")}
            icon={FaCheck}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const ConfirmButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type={type}
            label={t("Confirm")}
            disabled={disabled}
            icon={FaCheck}
            onClick={onClick}
        />
    );
};

export const CancelButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="secondary"
            type="button"
            label={t("Cancel")}
            disabled={disabled}
            icon={VscChromeClose}
            onClick={onClick}
        />
    );
};

export const BackButton: React.FC<{
    onClick: () => void;
    label?: string;
    disabled?: boolean;
}> = ({ onClick, label, disabled }): ReactElement<typeof Button> => (
    <SbButton
        variant="secondary"
        type="button"
        label={label ?? "Back"}
        disabled={disabled}
        icon={VscTriangleLeft}
        onClick={onClick}
    />
);

export const SearchButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type="button"
            label={t("Search")}
            onClick={onClick}
            disabled={disabled}
        />
    );
};

export const ResetButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="secondary"
            type="button"
            label={t("Reset")}
            onClick={onClick}
            disabled={disabled}
        />
    );
};

export const DeleteButton: React.FC<{
    disabled?: boolean;
    onClick: () => void;
}> = ({ disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="danger"
            type="button"
            label={t("Delete")}
            icon={RiDeleteBinFill}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const PromoteButton: React.FC<{
    disabled?: boolean;
    onClick: () => void;
}> = ({ disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type="button"
            label={t("Promote")}
            icon={FaLevelUpAlt}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const DemoteButton: React.FC<{
    disabled?: boolean;
    onClick: () => void;
}> = ({ disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="warning"
            type="button"
            label={t("Demote")}
            icon={FaLevelDownAlt}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const DissociateButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="danger"
            type="button"
            label={t("Dissociate")}
            icon={FaUnlink}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const AssociateButton: React.FC<{
    disabled?: boolean;
    onClick: () => void;
}> = ({ disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type="button"
            label={t("Associate")}
            icon={FaLink}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

export const CloneButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
    label?: string;
}> = ({ onClick, label, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    return (
        <SbButton
            variant="primary-transparent"
            hoverVariant={"none"}
            type="button"
            label={label ?? t("Clone")}
            icon={FaClone}
            disabled={disabled}
            onClick={onClick}
            alignItemsCenter={false}
        />
    );
};

export const TriggerActionItemButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    return (
        <SbButton
            variant={"primary"}
            type="button"
            label={t("TriggerActionItem")}
            disabled={disabled}
            icon={FaFlag}
            onClick={onClick}
        />
    );
};

export const ViewQuestionDetailsButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    return (
        <SbButton
            variant={"primary"}
            type="button"
            label={t("ViewQuestionDetails")}
            disabled={disabled}
            icon={FaInfoCircle}
            onClick={onClick}
        />
    );
};

export const ReviewQuestionButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => (
    <SbButton
        variant={"primary"}
        type="button"
        label={"Review question"}
        disabled={disabled}
        icon={FaCheckSquare}
        onClick={onClick}
    />
);

export const AddCommentButton: React.FC<{
    onClick: () => void;
    disabled?: boolean;
}> = ({ onClick, disabled }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    return (
        <SbButton
            variant={"primary"}
            type="button"
            label={t("AddComment")}
            disabled={disabled}
            icon={FaComment}
            onClick={onClick}
        />
    );
};

export const ResendButton: React.FC<{
    type: "submit" | "reset" | "button" | null;
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
}> = ({ type, label, disabled, onClick }): ReactElement<typeof Button> => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <SbButton
            variant="primary"
            type={type}
            label={label ?? t("Resend")}
            icon={FaRedo}
            disabled={disabled}
            onClick={onClick}
        />
    );
};

const buildLabel = (
    icon: IconType | undefined,
    label: string,
    iconSize: "sm" | "lg" | "xl" | null | undefined,
    iconPosition: "left" | "right"
): ReactElement<IconType> => (
    <>
        {icon &&
            iconPosition === "left" &&
            React.createElement(icon, {
                size: getIconButtonSize(iconSize ? iconSize : "lg"),
            })}

        {label}

        {icon &&
            iconPosition === "right" &&
            React.createElement(icon, {
                size: getIconButtonSize(iconSize ? iconSize : "lg"),
            })}
    </>
);

const getIconButtonSize = (iconSize: string): string => {
    const themeContext = useContext(ThemeContext);
    switch (iconSize) {
        case "sm":
            return themeContext.padding.sm;
        case "lg":
            return themeContext.padding.lg;
        case "xl":
            return themeContext.padding.xl;
        default:
            return themeContext.padding.xl;
    }
};

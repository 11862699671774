import jwtDecode from "jwt-decode";
import { useState } from "react";
import { Badge, Card, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    FaArrowCircleDown,
    FaCheck,
    FaClock,
    FaCoffee,
    FaComments,
    FaEye,
    FaFolderOpen,
    FaLightbulb,
    FaPlay,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { ActionItems, Common } from "../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../core/hooks/loaderManager";
import { createNavigateSearchParameter, useNavigateSearch } from "../../core/hooks/navigateSearch";
import { useAuth } from "../../core/store/auth-context";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../core/store/toast-context";
import { DetailsValue, EndAlignedDiv } from "../../core/theme/global-styles";
import { actionItemActions, DrawerTitles } from "../../core/utilities/enums";
import { getIcon } from "../../core/utilities/getIcon";
import { getPath } from "../../core/utilities/getPath";
import {
    areMutationsLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../core/utilities/responseStateHelper";
import queryClient from "../../data/query-client";
import { CancelMyActionItemDto } from "../../domain/dtos/action-items/cancel-my-action-item-dto";
import { CompleteMyActionItemDto } from "../../domain/dtos/action-items/complete-my-action-item-dto";
import { ExtendActionItemDto } from "../../domain/dtos/action-items/extend-action-item-dto";
import { MyActionItemsDto } from "../../domain/dtos/action-items/my-action-items-dto";
import { PlaceOnHoldActionItemDto } from "../../domain/dtos/action-items/place-on-hold-action-item-dto";
import { ReactivateOnHoldActionItemDto } from "../../domain/dtos/action-items/reactivate-on-hold-action-item-dto";
import { ReopenFinalizedActionItemDto } from "../../domain/dtos/action-items/reopen-finalized-action-item-dto";
import { ActionInstanceStatus } from "../../domain/enums/action-items/ActionInstanceStatus";
import { WebTokenType } from "../../domain/responses/account/token-response";
import {
    useFilterAssociatedRaciSetUsers,
    useMarkCommentsAsRead,
} from "../../domain/viewmodels/action-items/action-item-card-viewmodel";
import {
    useCancelMyActionItem,
    useCompleteMyActionItem,
    useExtendMyActionItem,
    usePlaceOnHold,
    useReactivateOnHold,
} from "../../domain/viewmodels/action-items/my-action-item-details-viewmodel";
import { useReopenFinalizedActionItem } from "../../domain/viewmodels/action-items/view-finalized-action-items-viewmodel";
import SbActionItemExtended from "../atoms/SbActionItemExtended";
import SbActionItemStatusIconLabel from "../atoms/SbActionItemStatusIconLabel";
import { SbButton } from "../atoms/SbButton";
import SbIconLabel from "../atoms/SbIconLabel";
import { translateText } from "../helpers/translate";
import ActionItemComments from "../organisms/ActionItemComments";
import ActionItemActionConfirmation from "./ActionItemActionConfirmation";
import ActionItemDetails from "./ActionItemDetails";

const StyledCard = styled(Card)<StyledStatusProps>`
    width: 99%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-bottom: ${(props) => props.theme.padding.lg};
    padding: 0;
    box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey};

    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Pending &&
        css`
            border-left: ${(props) =>
                props.theme.padding.md +
                " solid " +
                props.theme.palette.actionInstanceStatus.pending};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Overdue &&
        css`
            border-left: ${(props) =>
                props.theme.padding.md +
                " solid " +
                props.theme.palette.actionInstanceStatus.overdue};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Cancelled &&
        css`
            border-left: ${(props) =>
                props.theme.padding.md +
                " solid " +
                props.theme.palette.actionInstanceStatus.cancelled};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Completed &&
        css`
            border-left: ${(props) =>
                props.theme.padding.md +
                " solid " +
                props.theme.palette.actionInstanceStatus.completed};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Due &&
        css`
            border-left: ${(props) =>
                props.theme.padding.md + " solid " + props.theme.palette.actionInstanceStatus.due};
        `}
    ${({ $actionInstanceStatus }) =>
        $actionInstanceStatus === ActionInstanceStatus.Open &&
        css`
            border-left: ${(props) =>
                props.theme.padding.md + " solid " + props.theme.palette.actionInstanceStatus.open};
        `}
`;

const StyledCardBody = styled(Card.Body)`
    padding-top: 0;
    padding-bottom: ${(props) => props.theme.padding.md};
    display: flex;
    flex-direction: column;
`;

const StyledRow = styled(Row)`
    padding-top: ${(props) => props.theme.padding.xs};
    padding-bottom: ${(props) => props.theme.padding.xs};
    border-bottom: ${(props) => props.theme.padding.xxs + " solid " + props.theme.palette.grey};
`;

const StyledActionsRow = styled(Row)`
    padding-top: ${(props) => props.theme.padding.lg};
`;

const StyledText = styled.div`
    padding-left: ${(props) => props.theme.padding.sm};
`;

const StyledDropdown = styled(Dropdown)`
    height: 100%;
    overflow: visible;
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.white};
    padding-left: ${(props) => props.theme.padding.lg};
    padding-right: ${(props) => props.theme.padding.lg};
    background-color: ${(props) => props.theme.palette.primary};
    border-color: ${(props) => props.theme.palette.primary};
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
    padding-left: ${(props) => props.theme.padding.md};
    padding-right: ${(props) => props.theme.padding.md};
`;

const StyledDropdownItem = styled(Dropdown.Item)`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.palette.black};
    gap: ${(props) => props.theme.padding.md};

    &:hover {
        background-color: ${(props) => props.theme.palette.grey};
        color: ${(props) => props.theme.palette.black};
        border-color: ${(props) => props.theme.palette.grey};
    }
`;

const StyledCommentButton = styled.div`
    color: ${(props) => props.theme.palette.black};
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const StyledRowPadding = styled(Row)`
    padding: ${(props) => props.theme.padding.md};
`;

export interface StyledStatusProps {
    $actionInstanceStatus: ActionInstanceStatus;
}

export const ActionItemCard: React.FC<{ actionItem: MyActionItemsDto }> = ({ actionItem }) => {
    const [showActionItemActionConfirmation, setShowActionItemActionConfirmation] = useState(false);
    const [showActionItemDetails, setShowActionItemDetails] = useState(false);
    const [showActionItemComments, setShowActionItemComments] = useState(false);
    const [selectedActionItemAction, setSelectedActionItemAction] = useState("");

    const navigate = useNavigate();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const associatedRaciSetUsers = useFilterAssociatedRaciSetUsers(actionItem.actionItemId);
    const markCommentsAsRead = useMarkCommentsAsRead();

    const associatedRaciSetUsersData = associatedRaciSetUsers.data;

    const extendMyActionItem = useExtendMyActionItem();
    const placeOnHold = usePlaceOnHold();
    const reactivateOnHold = useReactivateOnHold();
    const completeMyActionItem = useCompleteMyActionItem();
    const cancelMyActionItem = useCancelMyActionItem();
    const reopenFinalizedActionItem = useReopenFinalizedActionItem();

    useLoader(
        isQueryLoading(associatedRaciSetUsers) ||
            areMutationsLoading([
                extendMyActionItem,
                placeOnHold,
                reactivateOnHold,
                completeMyActionItem,
                cancelMyActionItem,
                reopenFinalizedActionItem,
            ]),
        ActionItemCard
    );

    const getMoreDropdownOptions = (): string[] => {
        const moreDropdownOptions = [
            actionItemActions.Extend,
            actionItemActions.PlaceOnHold,
            actionItemActions.CancelItem,
            actionItemActions.Edit,
            actionItemActions.Clone,
        ];

        if (actionItem.isExtended) {
            return moreDropdownOptions.filter(
                (moreDropdownOption) => moreDropdownOption !== actionItemActions.Extend
            );
        }

        return moreDropdownOptions;
    };

    const getAllowedDropDownOptionsForUser = (): string[] => {
        const allowedDropDownOptionsForUser: string[] = [];

        const refreshToken = JSON.parse(localStorage["refreshToken"]);
        const decodedRefreshToken = jwtDecode<WebTokenType>(refreshToken.refreshToken);
        const authUserEmail = decodedRefreshToken.email;

        if (
            associatedRaciSetUsersData!.find(
                (x) =>
                    x.value.toLowerCase().includes(authUserEmail.toLowerCase()) &&
                    (x.label === "Responsible" || x.label === "Accountable")
            )
        ) {
            allowedDropDownOptionsForUser.push(actionItemActions.Extend);
            allowedDropDownOptionsForUser.push(actionItemActions.PlaceOnHold);
            allowedDropDownOptionsForUser.push(actionItemActions.Edit);
            allowedDropDownOptionsForUser.push(actionItemActions.Clone);
        }

        associatedRaciSetUsersData!.find(
            (x) =>
                x.value.toLowerCase().includes(authUserEmail.toLowerCase()) &&
                x.label === "Responsible"
        ) && allowedDropDownOptionsForUser.push(actionItemActions.CancelItem);

        return allowedDropDownOptionsForUser;
    };

    const isUserAllowedToActionThisOption = (option: string): boolean =>
        getAllowedDropDownOptionsForUser().find((x) => x === option) !== undefined;

    const getNumberOfUnreadNotifications = (): number | null => {
        const authUserId = auth.userId;

        const userNotifications = actionItem.actionItemInstanceCommentNotificationDtos?.filter(
            (x) => x.userId == authUserId && !x.isRead
        );

        return userNotifications.length > 0 ? userNotifications.length : null;
    };

    const onCommentsIconClick = (): void => {
        setShowActionItemComments(!showActionItemComments);

        getNumberOfUnreadNotifications() &&
            markCommentsAsRead.mutate(actionItem.actionItemInstanceId, {
                onSuccess: async () => {
                    actionItem.actionItemInstanceCommentNotificationDtos = [];
                },
                onError: errorResponseToDisplayHandler,
            });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        switch (selectedActionItemAction) {
            case actionItemActions.Extend:
                return extendMyActionItem.mutate(
                    new ExtendActionItemDto(formData, actionItem.actionItemInstanceId),
                    {
                        onSuccess: async () => {
                            toast.addToast(
                                createSuccessToastProps([t("ActionItemExtendedSuccessfully")])
                            );
                            queryClient.refetchQueries(["filterMyActionItems"]);
                            setShowActionItemActionConfirmation(false);
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            case actionItemActions.PlaceOnHold:
                return placeOnHold.mutate(
                    new PlaceOnHoldActionItemDto(formData, actionItem.actionItemInstanceId),
                    {
                        onSuccess: async () => {
                            toast.addToast(
                                createSuccessToastProps([t("ActionItemPlacedOnHoldSuccessfully")])
                            );
                            queryClient.refetchQueries(["filterMyActionItems"]);
                            setShowActionItemActionConfirmation(false);
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            case actionItemActions.ReActivate:
                return reactivateOnHold.mutate(
                    new ReactivateOnHoldActionItemDto(formData, actionItem.actionItemInstanceId),
                    {
                        onSuccess: async () => {
                            toast.addToast(createSuccessToastProps([t("ReactivateSuccess")]));
                            queryClient.refetchQueries(["filterMyActionItems"]);
                            setShowActionItemActionConfirmation(false);
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            case actionItemActions.Complete:
                return completeMyActionItem.mutate(
                    new CompleteMyActionItemDto(formData, actionItem.actionItemInstanceId),
                    {
                        onSuccess: async () => {
                            toast.addToast(createSuccessToastProps([t("CompleteSuccess")]));
                            queryClient.refetchQueries(["filterMyActionItems"]);
                            setShowActionItemActionConfirmation(false);
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            case actionItemActions.CancelItem: {
                if (!formData.get("comment")) {
                    toast.addToast(createErrorToastProps([t("CommentRequired")]));
                    return;
                } else {
                    return cancelMyActionItem.mutate(
                        new CancelMyActionItemDto(formData, actionItem.actionItemInstanceId),
                        {
                            onSuccess: async () => {
                                toast.addToast(createSuccessToastProps([t("CancelSuccess")]));
                                queryClient.refetchQueries(["filterMyActionItems"]);
                                setShowActionItemActionConfirmation(false);
                            },
                            onError: errorResponseToDisplayHandler,
                        }
                    );
                }
            }

            case actionItemActions.Reopen:
                return reopenFinalizedActionItem.mutate(
                    new ReopenFinalizedActionItemDto(formData, actionItem.actionItemInstanceId),
                    {
                        onSuccess: async () => {
                            toast.addToast(createSuccessToastProps([t("ReopenSuccess")]));
                            queryClient.refetchQueries(["filterFinalizedActionItems"]);
                            setShowActionItemActionConfirmation(false);
                        },
                        onError: errorResponseToDisplayHandler,
                    }
                );
            default:
                return;
        }
    };

    const extendClick = (): void => {
        setShowActionItemActionConfirmation(true);
        setSelectedActionItemAction(actionItemActions.Extend);
    };

    const placeOnHoldClick = (): void => {
        setShowActionItemActionConfirmation(true);
        setSelectedActionItemAction(actionItemActions.PlaceOnHold);
    };

    const reActivateClick = (): void => {
        setShowActionItemActionConfirmation(!showActionItemActionConfirmation);
        setSelectedActionItemAction(actionItemActions.ReActivate);
    };

    const completeClick = (): void => {
        setShowActionItemActionConfirmation(!showActionItemActionConfirmation);
        setSelectedActionItemAction(actionItemActions.Complete);
    };

    const reopenClick = (): void => {
        setShowActionItemActionConfirmation(!showActionItemActionConfirmation);
        setSelectedActionItemAction(actionItemActions.Reopen);
    };

    const cancelClick = (): void => {
        setShowActionItemActionConfirmation(true);
        setSelectedActionItemAction(actionItemActions.CancelItem);
    };

    const cloneClick = (): void => {
        setShowActionItemActionConfirmation(true);
        setSelectedActionItemAction(actionItemActions.Clone);
        navigate(`${getPath(DrawerTitles.ActionItems)}/${actionItem.actionItemId}/create`);
    };

    const navigateToEditActionItem = (): void => {
        const params = [
            createNavigateSearchParameter(
                "actionItemInstanceId",
                actionItem.actionItemInstanceId.toString()
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${actionItem.actionItemId}`, params);
    };

    const moreDropdownOptionClick = (moreDropdownOption: string): void => {
        switch (moreDropdownOption) {
            case actionItemActions.Extend:
                return extendClick();
            case actionItemActions.PlaceOnHold:
                return placeOnHoldClick();
            case actionItemActions.CancelItem:
                return cancelClick();
            case actionItemActions.Edit:
                return navigateToEditActionItem();
            case actionItemActions.Clone:
                return cloneClick();
            default:
                return;
        }
    };

    const renderActionItemActionConfirmation = (): JSX.Element => {
        switch (selectedActionItemAction) {
            case actionItemActions.Extend:
                return (
                    <StyledRowPadding>
                        <ActionItemActionConfirmation
                            confirmationText={t("ExtendConfirmation")}
                            commentLabelText={`${t("ProvideCommentForExtended")}`}
                            handleSubmit={handleSubmit}
                            selectDate
                            setShowActionItemActionConfirmation={
                                setShowActionItemActionConfirmation
                            }
                        />
                    </StyledRowPadding>
                );
            case actionItemActions.PlaceOnHold:
                return (
                    <StyledRowPadding>
                        <ActionItemActionConfirmation
                            confirmationText={t("AreYouSureYouWantToPlaceThisItemOnHold")}
                            commentLabelText={`${t("ProvideCommentForOnHold")}`}
                            handleSubmit={handleSubmit}
                            setShowActionItemActionConfirmation={
                                setShowActionItemActionConfirmation
                            }
                        />
                    </StyledRowPadding>
                );
            case actionItemActions.ReActivate:
                return (
                    <StyledRowPadding>
                        <ActionItemActionConfirmation
                            confirmationText={
                                actionItem.actionInstanceStatus === ActionInstanceStatus.Overdue
                                    ? `${t("PleaseProvideAnExtendedDueDateForOverdueOnHoldItem")}`
                                    : `${t("AreYouSureYouWantToReactivateThisItem")}`
                            }
                            selectDate={
                                actionItem.actionInstanceStatus === ActionInstanceStatus.Overdue
                            }
                            handleSubmit={handleSubmit}
                            setShowActionItemActionConfirmation={
                                setShowActionItemActionConfirmation
                            }
                        />
                    </StyledRowPadding>
                );
            case actionItemActions.Complete:
                return (
                    <StyledRowPadding>
                        <ActionItemActionConfirmation
                            confirmationText={t("ConfirmComplete")}
                            commentLabelText={
                                actionItem.actionInstanceStatus === ActionInstanceStatus.Overdue
                                    ? `${t("ProvideCommentForCompleted")}`
                                    : undefined
                            }
                            handleSubmit={handleSubmit}
                            setShowActionItemActionConfirmation={
                                setShowActionItemActionConfirmation
                            }
                        />
                    </StyledRowPadding>
                );
            case actionItemActions.CancelItem:
                return (
                    <StyledRowPadding>
                        <ActionItemActionConfirmation
                            confirmationText={t("CancelConfirmation")}
                            commentLabelText={`${t("ProvideCommentForCancelled")}`}
                            handleSubmit={handleSubmit}
                            setShowActionItemActionConfirmation={
                                setShowActionItemActionConfirmation
                            }
                        />
                    </StyledRowPadding>
                );
            case actionItemActions.Reopen:
                return (
                    <StyledRowPadding>
                        <ActionItemActionConfirmation
                            confirmationText={t("AreYouSureYouWantToReopenThisItem")}
                            commentLabelText={`${t("ProvideCommentForReopened")}`}
                            handleSubmit={handleSubmit}
                            setShowActionItemActionConfirmation={
                                setShowActionItemActionConfirmation
                            }
                        />
                    </StyledRowPadding>
                );
            default:
                return <></>;
        }
    };

    const renderActionItemDetails = (): JSX.Element => (
        <StyledRowPadding>
            <ActionItemDetails
                actionItemId={actionItem.actionItemId}
                actionItemInstanceId={actionItem.actionItemInstanceId}
                isFinalizedItem={isFinalizedItem}
            />
        </StyledRowPadding>
    );

    const renderActionItemComments = (): JSX.Element => (
        <StyledRowPadding>
            <ActionItemComments actionItem={actionItem} />
        </StyledRowPadding>
    );

    const isFinalizedItem =
        actionItem.actionInstanceStatus === ActionInstanceStatus.Completed ||
        actionItem.actionInstanceStatus === ActionInstanceStatus.Cancelled;

    return (
        <Row>
            {isQuerySuccessful(associatedRaciSetUsers) && (
                <StyledCard $actionInstanceStatus={actionItem.actionInstanceStatus}>
                    <StyledCardBody>
                        <StyledRow>
                            <Col>
                                <SbIconLabel
                                    label={`${actionItem.actionItemId} : ${actionItem.name}`}
                                />
                            </Col>
                            <Col>
                                <SbIconLabel
                                    icon={FaLightbulb}
                                    iconSize="lg"
                                    label={`${t("StartDate")} : ${actionItem.startDateLocal}`}
                                />
                            </Col>
                            <Col>
                                <SbIconLabel
                                    icon={FaClock}
                                    iconSize="lg"
                                    label={`${t("Due")} : ${actionItem.dueDateLocal}`}
                                />
                            </Col>
                            <Col>
                                {actionItem.isOnHold && (
                                    <SbIconLabel
                                        icon={FaCoffee}
                                        iconSize="lg"
                                        label={t("OnHold")}
                                    />
                                )}
                            </Col>
                            <Col md="1">
                                <SbActionItemStatusIconLabel
                                    actionInstanceStatus={actionItem.actionInstanceStatus}
                                />
                            </Col>
                        </StyledRow>
                        <StyledActionsRow>
                            <Col>
                                <DetailsValue>{actionItem.description}</DetailsValue>
                            </Col>
                            <Col>
                                <EndAlignedDiv>
                                    {actionItem.isOnHold ? (
                                        <SbButton
                                            variant="secondary"
                                            type={"button"}
                                            hoverVariant="border"
                                            label={t("ReActivate")}
                                            onClick={() => reActivateClick()}
                                            icon={FaPlay}
                                            iconSize="lg"
                                        />
                                    ) : (
                                        <>
                                            {isFinalizedItem ? (
                                                <SbButton
                                                    variant="secondary"
                                                    type={"button"}
                                                    hoverVariant="border"
                                                    label={t("Reopen")}
                                                    onClick={() => reopenClick()}
                                                    icon={FaFolderOpen}
                                                    iconSize="lg"
                                                />
                                            ) : (
                                                <SbButton
                                                    variant="success"
                                                    type={"button"}
                                                    hoverVariant="border"
                                                    label={t("Complete")}
                                                    onClick={() => completeClick()}
                                                    icon={FaCheck}
                                                    iconSize="lg"
                                                />
                                            )}
                                            <SbButton
                                                variant="advance"
                                                hoverVariant="border"
                                                type={"button"}
                                                label={t("Details", { keyPrefix: Common })}
                                                onClick={() =>
                                                    setShowActionItemDetails(!showActionItemDetails)
                                                }
                                                icon={FaEye}
                                                iconSize="lg"
                                            />
                                            {isFinalizedItem ? (
                                                <></>
                                            ) : (
                                                <StyledDropdown>
                                                    <StyledDropdownToggle id="dropdown-custom-1">
                                                        <FaArrowCircleDown />
                                                        <StyledText>
                                                            {t("More", { keyPrefix: Common })}
                                                        </StyledText>
                                                    </StyledDropdownToggle>
                                                    <StyledDropdownMenu align="right">
                                                        {getMoreDropdownOptions().map(
                                                            (moreDropdownOption, index) => (
                                                                <div
                                                                    key={moreDropdownOption + index}
                                                                    onClick={() =>
                                                                        isUserAllowedToActionThisOption(
                                                                            moreDropdownOption
                                                                        ) &&
                                                                        moreDropdownOptionClick(
                                                                            moreDropdownOption
                                                                        )
                                                                    }
                                                                >
                                                                    <StyledDropdownItem
                                                                        disabled={
                                                                            !isUserAllowedToActionThisOption(
                                                                                moreDropdownOption
                                                                            )
                                                                        }
                                                                        eventKey={
                                                                            moreDropdownOption
                                                                        }
                                                                    >
                                                                        {getIcon(
                                                                            moreDropdownOption
                                                                        )}
                                                                        {translateText(
                                                                            t,
                                                                            moreDropdownOption,
                                                                            ActionItems
                                                                        )}
                                                                    </StyledDropdownItem>
                                                                </div>
                                                            )
                                                        )}
                                                    </StyledDropdownMenu>
                                                </StyledDropdown>
                                            )}

                                            <StyledCommentButton
                                                onClick={() => onCommentsIconClick()}
                                            >
                                                <FaComments size={32} />
                                                {getNumberOfUnreadNotifications() && (
                                                    <Badge bg="danger">
                                                        {getNumberOfUnreadNotifications()}
                                                    </Badge>
                                                )}
                                            </StyledCommentButton>
                                        </>
                                    )}
                                </EndAlignedDiv>
                            </Col>
                        </StyledActionsRow>

                        {showActionItemActionConfirmation && renderActionItemActionConfirmation()}

                        {showActionItemDetails && renderActionItemDetails()}

                        {showActionItemComments && renderActionItemComments()}

                        {actionItem.isExtended && <SbActionItemExtended />}
                    </StyledCardBody>
                </StyledCard>
            )}
        </Row>
    );
};

import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { createChecklist } from "../../../data/services/checklists/checklist-service";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    formData: FormData;
}

export const useCreateChecklist = (): UseMutationResult<
    Response<number>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            name: formData.get("name")! as string,
            description: formData.get("description")! as string,
            isTraining: convertToggleValueToBoolean(formData.get("isTraining")! as string),
        };

        return createChecklist(url.baseUrl, request);
    });
};

import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { createAndAssociateQuestionSet } from "../../../data/services/checklists/checklist-service";
import { createAssociateNewQuestionSetToChecklistRequest } from "../../requests/checklists/create-and-associate-question-set-request";
import { createCreateQuestionSetRequest } from "../../requests/question-set/create-question-set-request";
import { QuestionSetScheduleRequest } from "../../requests/question-set/create-question-set-schedule-request";
import { Response } from "../../responses/common/response-response";

interface CreateAndAssociateParameters {
    checklistId: number;
    formData: FormData;
}

export const useCreateAndAssociateQuestionSet = (
    questionSetScheduleRequest: QuestionSetScheduleRequest
): UseMutationResult<Response<number>, HTTPError, CreateAndAssociateParameters> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateAndAssociateParameters) => {
        const formData = mutationKey.formData;

        let createQuestionSetRequests = [];
        const createQuestionSetRequest = createCreateQuestionSetRequest(
            formData.get("name")! as string,
            formData.get("description")! as string,
            formData.get("process")! as string,
            convertToggleValueToBoolean(formData.get("arMonitoring")! as string),
            questionSetScheduleRequest
        );

        createQuestionSetRequests.push(createQuestionSetRequest);

        const request = createAssociateNewQuestionSetToChecklistRequest(
            mutationKey.checklistId,
            createQuestionSetRequest
        );

        return createAndAssociateQuestionSet(url.baseUrl, request);
    });
};

import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    useEditQuestionCategory,
    useGetQuestionCategoryDetails,
} from "../../../domain/viewmodels/question-categories/edit-question-category-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditQuestionCategoryContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionCategoryId = Number(useParams().questionCategoryId);
    const questionCategoryDetails = useGetQuestionCategoryDetails(questionCategoryId);
    const questionCategoryDetailsData = questionCategoryDetails.data;

    const editQuestionCategory = useEditQuestionCategory();

    useLoader(
        isQueryLoading(questionCategoryDetails) || isMutationLoading(editQuestionCategory),
        EditQuestionCategoryContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionCategories);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        editQuestionCategory.mutate(
            {
                questionCategoryId: questionCategoryId,
                formData: formData,
            },
            {
                onSuccess: async () => navigate(-1),
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>{t("EditQuestionCategory")}</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(questionCategoryDetails) && (
                <TextTitledPanel title="Edit Question Category">
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name="name"
                            label={t("Name")}
                            maxLength={100}
                            type="text"
                            defaultValue={questionCategoryDetailsData!.name}
                            required
                        />
                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditQuestionCategoryContainer;

import { Response } from "../../responses/common/response-response";
import { QuestionCategoryResponse } from "../../responses/question-category/question-category-response";
import { QuestionCategoryWithQuestionCountResponse } from "../../responses/question-category/question-category-with-question-count-response";

export interface QuestionCategoryDto {
    questionCategoryId: number;
    name: string;
}

export const createQuestionCategoryDto = (
    questionCategoryResponse: QuestionCategoryResponse
): QuestionCategoryDto => ({
    questionCategoryId: questionCategoryResponse.questionCategoryId,
    name: questionCategoryResponse.name,
});

export const toQuestionCategoryDto = (
    response: Response<QuestionCategoryResponse>
): QuestionCategoryDto => createQuestionCategoryDto(response.data);

export const toQuestionCategoryDtos = (
    response: Response<QuestionCategoryResponse[]>
): QuestionCategoryDto[] => response.data.map((x) => createQuestionCategoryDto(x));

export interface QuestionCategoryWithQuestionCountDto extends QuestionCategoryDto {
    questionCount: number;
}

export const createQuestionCategoryWithQuestionCountDto = (
    questionCategoryResponse: QuestionCategoryWithQuestionCountResponse
): QuestionCategoryWithQuestionCountDto => ({
    questionCategoryId: questionCategoryResponse.questionCategoryId,
    name: questionCategoryResponse.name,
    questionCount: questionCategoryResponse.questionCount,
});

export const QuestionCategoryWithQuestionCountDto = (
    data: QuestionCategoryWithQuestionCountResponse[]
): QuestionCategoryWithQuestionCountDto[] =>
    data.map((questionCategory) => createQuestionCategoryWithQuestionCountDto(questionCategory));

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import {
    growStyledContentContainer,
    shrinkStyledContentContainer,
} from "../../core/animations/styledContentContainer";
import { reactPlugin } from "../../core/application-insights/application-insights-service";
import { AuthenticatedRoutes } from "../../core/routes/authenticated-routes";
import { useAuth } from "../../core/store/auth-context";
import { LoaderProvider } from "../../core/store/loader-context";
import { useMenu } from "../../core/store/menu-context";
import { ToastProvider } from "../../core/store/toast-context";
import { useUrl } from "../../core/store/url-context";
import { useWizardNotification } from "../../core/store/wizard-notification-context";
import { convertBooleanStringToBoolean } from "../../core/utilities/convertToBoolean";
import { getPath } from "../../core/utilities/getPath";
import queryClient from "../../data/query-client";
import { transformToRoleEnums } from "../../domain/enums/Roles";
import { WebTokenType } from "../../domain/responses/account/token-response";
import { AnswerCaptureProvider } from "../../domain/stores/answer-capture-context";
import { Loader } from "../atoms/Loader";
import { SbToast } from "../atoms/SbToast";
import CookiesBanner from "../organisms/CookiesBanner";
import Drawer from "../organisms/Drawer";
import Footer from "../organisms/Footer";
import AskNala from "../organisms/nala/AskNala";
import Navbar from "../organisms/Navbar";
import NonProdBanner from "../organisms/NonProdBanner";
import { WizardCardsModal } from "../organisms/WizardCardsModal";
import LoginContainer from "../templates/LoginContainer";
import LogoutContainer from "../templates/LogoutContainer";
import { WizardNotificationChecker } from "../templates/wizards/WizardNotificationChecker";
import { RedirectToLogin } from "./RedirectToLogin";

export interface StyledContentContainerProps {
    readonly $drawerOpen: boolean;
    readonly $animate: boolean;
}

const StyledContentContainer = styled(Container)<StyledContentContainerProps>`
    position: relative;
    height: 100%;
    z-index: 0;
    min-height: 100vh;
    font-family: ${(props) => props.theme.font.family};
    padding-top: ${(props) => props.theme.padding.navbar};
    padding-left: ${(props) =>
        props.$drawerOpen ? props.theme.padding.drawerOpen : props.theme.padding.drawerClosed};
    padding-right: 0px;
    animation: ${(props) =>
        props.$animate
            ? props.$drawerOpen
                ? shrinkStyledContentContainer
                : growStyledContentContainer
            : undefined} 0.3s linear;
    background-color: ${(props) => props.theme.palette.white};
    padding-bottom: 5.5rem; /* Footer height */
}
`;

const StyledPadding = styled.div`
    padding: ${(props) => props.theme.padding.lg};
`;

function App(): JSX.Element {
    const menu = useMenu();
    const auth = useAuth();
    const url = useUrl();
    const wizardNotification = useWizardNotification();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            const parsedToken = JSON.parse(token);

            const decodedToken = jwtDecode<WebTokenType>(parsedToken.token);
            const roles =
                decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const transformedRoles = transformToRoleEnums(!Array.isArray(roles) ? [roles] : roles);

            auth.updateUserName(decodedToken.name);
            auth.updateEmail(decodedToken.email);
            auth.updateUserRoles(transformedRoles);
            auth.updateTenantId(Number(decodedToken.tenantId));
            auth.updateUserId(Number(decodedToken.userId));
        }

        const isImpersonating = localStorage.getItem("isImpersonating");
        if (isImpersonating != null) {
            auth.updateIsImpersonating(convertBooleanStringToBoolean(isImpersonating));
        }

        const impersonatedTenant = localStorage.getItem("impersonatedTenant");
        if (impersonatedTenant) {
            auth.updateTenantId(Number(impersonatedTenant) || 0);
        }

        const isCookiesAccepted = localStorage.getItem("isCookiesAccepted");
        if (isCookiesAccepted) {
            auth.updateIsCookiesAccepted(convertBooleanStringToBoolean(isCookiesAccepted));
        }

        const impersonatedBaseUrl = localStorage.getItem("impersonatedBaseUrl");
        if (impersonatedBaseUrl) {
            url.updateBaseUrl(impersonatedBaseUrl);
        }
    }, []);

    return (
        <>
            <NonProdBanner />
            <QueryClientProvider client={queryClient}>
                {!auth.isAdmin && auth.tenantId ? (
                    <WizardNotificationChecker userId={auth.userId} />
                ) : (
                    <></>
                )}
                {wizardNotification.show && wizardNotification.wizardCards && (
                    <WizardCardsModal
                        tenant={auth.tenantId ? true : false}
                        show={wizardNotification.show}
                        updateShow={wizardNotification.updateShow}
                        wizardCards={wizardNotification.wizardCards}
                        getImageLibraryImage={wizardNotification.getImageLibraryImage}
                        activeIndex={wizardNotification.activeWizardCardIndex}
                        onActiveIndexChange={wizardNotification.onActiveWizardCardIndexChange}
                        isFinished={wizardNotification.isFinished}
                        wizardNotificationStatus={wizardNotification.wizardNotificationStatus}
                    />
                )}
                <Navbar />
                <Drawer />
                <AskNala />

                <AppInsightsContext.Provider value={reactPlugin}>
                    <LoaderProvider>
                        <ToastProvider>
                            <Loader />
                            <SbToast />
                            <StyledContentContainer
                                fluid
                                $animate={menu.animate}
                                $drawerOpen={menu.drawerOpen}
                            >
                                <AnswerCaptureProvider>
                                    <StyledPadding>
                                        <Routes>
                                            <Route
                                                path={getPath("Login")}
                                                element={<LoginContainer />}
                                            />
                                            <Route
                                                path={getPath("Logout")}
                                                element={<LogoutContainer />}
                                            />
                                            {AuthenticatedRoutes.map((authenticatedRoute) => (
                                                <Route
                                                    key={authenticatedRoute.path}
                                                    path={authenticatedRoute.path}
                                                    element={authenticatedRoute.element}
                                                />
                                            ))}
                                            <Route path="*" element={<RedirectToLogin />} />
                                        </Routes>
                                    </StyledPadding>
                                </AnswerCaptureProvider>
                            </StyledContentContainer>
                        </ToastProvider>
                    </LoaderProvider>
                </AppInsightsContext.Provider>

                <ReactQueryDevtools />
                <Footer animate={menu.animate} draweropen={menu.drawerOpen} />
                <CookiesBanner />
            </QueryClientProvider>
        </>
    );
}

export default App;

import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { referentialLinkColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterReferentialLinkTypesDto,
    FilterReferentialLinkTypeDto,
} from "../../../domain/dtos/referential-links/filter-referential-link-type-dto";
import { useCreateReferentialLink } from "../../../domain/viewmodels/referential-link-types/create-referential-link-viewmodel";
import { useSoftDeleteReferentialLink } from "../../../domain/viewmodels/referential-link-types/delete-referential-link-viewmodel";
import { useGetReferentialLinkTypeDetails } from "../../../domain/viewmodels/referential-link-types/view-referential-link-type-viewmodel";
import { useFilterReferentialLinks } from "../../../domain/viewmodels/referential-link-types/view-referential-links-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { EditLink, ViewLink } from "../../atoms/SbLink";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";
import { ReferentialLinksFilter } from "../../organisms/filters/ReferentialLinksFilter";

const StyledViewTextInput = styled(Form.Control)`
    width: 50%;
`;

interface SearchParams {
    referentialLinkTypeId: number | null;
    referentialLinkValue: string | null;
}

const createSearchParams = (
    referentialLinkTypeId: number | null,
    referentialLinkValue: string | null
): SearchParams => ({
    referentialLinkTypeId: referentialLinkTypeId,
    referentialLinkValue: referentialLinkValue,
});

const ViewReferentialLinkTypeContainer: React.FC = () => {
    const referentialLinkTypeId = Number(useParams().referentialLinkTypeId);

    const defaultSearchParams: SearchParams = createSearchParams(referentialLinkTypeId, null);

    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterReferentialLinkTypeDto>(
        defaultFilterReferentialLinkTypesDto
    );

    const menu = useMenu();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const queryClient = useQueryClient();

    const getReferentialLinkTypeDetails = useGetReferentialLinkTypeDetails(referentialLinkTypeId);
    const getReferentialLinks = useFilterReferentialLinks(filterDto);
    const createReferentialLink = useCreateReferentialLink();
    const softDeleteReferentialLink = useSoftDeleteReferentialLink();

    const getReferentialLinkTypeDetailsData = getReferentialLinkTypeDetails.data;

    useLoader(
        isQueryLoading(getReferentialLinks) ||
            isQueryLoading(getReferentialLinkTypeDetails) ||
            isMutationLoading(createReferentialLink) ||
            isMutationLoading(softDeleteReferentialLink),
        ViewReferentialLinkTypeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.ReferentialLinkTypes);
    }, []);

    useEffect(() => {
        setFilterDto({ ...filterDto, referentialLinkTypeId: referentialLinkTypeId });
    }, []);

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {buildEditLink()} {buildReferentialLinkTypesLink()}
            </>
        );
    };

    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink
            navigateTo={`${getPath(
                AccordionTitles.ReferentialLinkTypes
            )}/${referentialLinkTypeId}/edit`}
        />
    );

    const buildReferentialLinkTypesLink = (): ReactElement<typeof Link> => (
        <ViewLink
            label={t("ReferentialLinkTypes")}
            navigateTo={`${getPath(AccordionTitles.ReferentialLinkTypes)}`}
        />
    );

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const changeReferentialLinkValue = (referentialLinkValue: string): void => {
        setSearchParams({
            ...searchParams,
            referentialLinkValue: referentialLinkValue !== "" ? referentialLinkValue : null,
        });
    };

    const resetFilter = (): void => {
        setSearchParams({ ...defaultSearchParams, referentialLinkTypeId: referentialLinkTypeId });
        setFilterDto({
            ...filterDto,
            ...defaultSearchParams,
            referentialLinkTypeId: referentialLinkTypeId,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        createReferentialLink.mutate(
            {
                referentialLinkTypeId: referentialLinkTypeId,
                formData: new FormData(event.currentTarget),
            },
            {
                onSuccess: async () => {
                    toast.addToast(
                        createSuccessToastProps([t("AddedReferentialLinkSuccessMessage")])
                    );
                    queryClient.invalidateQueries(["filterReferentialLinks", filterDto]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const handleDeleteReferentialLink = (referentialLinkId: number): void => {
        softDeleteReferentialLink.mutate(referentialLinkId, {
            onSuccess: async () => {
                toast.addToast(
                    createSuccessToastProps([t("RemovedReferentialLinkSuccessMessage")])
                );
                queryClient.invalidateQueries(["getReferentialLinks", referentialLinkTypeId]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ReferentialLinkTypeDetails")}</PageHeading>
            <LargeVerticalSpace />

            <ContentContainer>
                <Row>
                    <Col md="auto">
                        <DetailsLabel>{t("Name")}</DetailsLabel>
                    </Col>
                    <Col md="auto">
                        <DetailsValue>{getReferentialLinkTypeDetailsData?.name}</DetailsValue>
                    </Col>
                </Row>

                <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
            </ContentContainer>
            <SectionVerticalSpace />

            <ReferentialLinksFilter
                referentialLinkValue={searchParams.referentialLinkValue}
                changeReferentialLinkValue={changeReferentialLinkValue}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <SbAccordion title={t("AssociatedReferentialLinks")}>
                <Form onSubmit={handleSubmit}>
                    <EndAlignedDiv>
                        <StyledViewTextInput
                            name={"referentialLinkValue"}
                            type={"text"}
                            minLength={2}
                            maxLength={150}
                            required
                        />
                        <SbButton
                            variant="primary"
                            type="submit"
                            label={t("Add", { keyPrefix: Common })}
                        />
                    </EndAlignedDiv>
                    {isQuerySuccessful(getReferentialLinks) && (
                        <DataTable
                            columns={referentialLinkColumnNames}
                            rows={getReferentialLinks.data!.rows}
                            deleteItem={handleDeleteReferentialLink}
                            totalItems={getReferentialLinks.data!.recordCount}
                            paginationDto={filterDto}
                            setPaginationDto={setFilterDto}
                        />
                    )}
                </Form>
            </SbAccordion>
        </>
    );
};

export default ViewReferentialLinkTypeContainer;

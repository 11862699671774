import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { convertToggleValueToBoolean } from "../../../core/utilities/convertToBoolean";
import { createQuestionSet } from "../../../data/services/question-set/question-set-service";
import { QuestionSetScheduleRequest } from "../../requests/question-set/create-question-set-schedule-request";
import { Response } from "../../responses/common/response-response";

interface CreateParameters {
    formData: FormData;
}

export const useCreateQuestionSet = (
    questionSetScheduleRequest: QuestionSetScheduleRequest
): UseMutationResult<Response<number>, HTTPError, CreateParameters> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const formData = mutationKey.formData;

        const request = {
            name: formData.get("name")! as string,
            description: formData.get("description")! as string,
            process: formData.get("process")! as string,
            isARMonitoring: convertToggleValueToBoolean(formData.get("arMonitoring")! as string),
            questionSetScheduleRequest: questionSetScheduleRequest,
        };

        return createQuestionSet(url.baseUrl, request);
    });
};

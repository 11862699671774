import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { editReferentialLinkType } from "../../../data/services/referential-link-types/referential-link-type-service";
import { Response } from "../../responses/common/response-response";

interface EditParameters {
    referentialLinkTypeId: number;
    formData: FormData;
}

export const useEditReferentialLinkType = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditParameters) => {
        const formData = mutationKey.formData;

        const request = {
            referentialLinkTypeId: mutationKey.referentialLinkTypeId,
            referentialLinkTypeName: formData.get("name")! as string,
        };

        return editReferentialLinkType(url.baseUrl, request);
    });
};

import React, { useContext } from "react";
import { IconType } from "react-icons";
import styled, { ThemeContext } from "styled-components";

const StyledIconLabelContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.padding.md};
    color: ${(props) => props.theme.palette.secondary};
`;

const StyledLabel = styled.div`
    font-weight: bold;
    font-size: ${(props) => props.theme.font.md};
`;

const SbIconLabel: React.FC<{
    icon?: IconType;
    iconSize?: "sm" | "lg" | "xl" | null;
    label: string;
}> = ({ icon, iconSize, label }) => {
    const themeContext = useContext(ThemeContext);

    const getIconSize = (iconSize: string): string => {
        switch (iconSize) {
            case "sm":
                return themeContext.padding.sm;
            case "lg":
                return themeContext.padding.lg;
            case "xl":
                return themeContext.padding.xl;
            default:
                return themeContext.padding.xl;
        }
    };

    return (
        <StyledIconLabelContainer>
            {icon != undefined &&
                React.createElement(icon, {
                    size: iconSize ? getIconSize(iconSize) : themeContext.padding.xl,
                })}
            <StyledLabel>{label}</StyledLabel>
        </StyledIconLabelContainer>
    );
};

export default SbIconLabel;

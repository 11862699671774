import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MoveNodeEvent } from "../../../core/constants/application-insights-events";
import { Common, Nodes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    NoDataStateDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import InheritedChecklistAssignmentDto from "../../../domain/dtos/checklist-assignment/inherited-checklist-assignment-dto";
import { NodeDescriptionDetailsDto } from "../../../domain/dtos/hierarchy/node-description-details-dto";
import {
    useGetMoveNodeDetails,
    useMoveNode,
} from "../../../domain/viewmodels/hierarchy/move-node-viewmodel";
import { CancelButton, ConfirmButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";

const StyledContainerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.theme.padding.lg};
`;

const StyledItemDiv = styled.div`
    flex: 1;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.palette.primary};
`;

interface MoveNodeProps {
    candidateNodeId: number;
    destinationNodeId: number;
}

const MoveNodeContainer: React.FC = () => {
    const menu = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const { candidateNodeId, destinationNodeId } = location.state as MoveNodeProps;

    const getMoveNodeDetails = useGetMoveNodeDetails(candidateNodeId, destinationNodeId);
    const moveNode = useMoveNode();

    const candidateNodeDetailsResponseData = getMoveNodeDetails[0].data;
    const destinationNodeDetailsResponseData = getMoveNodeDetails[1].data;
    const candidateNodeInheritedChecklistAssignmentResponseData = getMoveNodeDetails[2].data;
    const destinationNodeInheritedChecklistAssignmentResponseData = getMoveNodeDetails[3].data;

    useLoader(
        areQueriesLoading(getMoveNodeDetails) || isMutationLoading(moveNode),
        MoveNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const onMoveNodeSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, MoveNodeEvent);

        const params = [
            createNavigateSearchParameter("viewInHierarchyId", destinationNodeId.toString()),
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "NodeMovedSuccessfully"),
        ];

        navigateSearch(getPath(AccordionTitles.VisualTree), params);
    };

    const onMoveNodeConfirmed = (): void => {
        moveNode.mutate(
            {
                candidateNodeId: candidateNodeId,
                destinationNodeId: destinationNodeId,
            },
            {
                onSuccess: onMoveNodeSuccess,
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        MoveNodeEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildCandidateNodeDetailsContent = (): ReactElement<HTMLDivElement> => (
        <>
            {buildNodeDetailsContent(candidateNodeDetailsResponseData!)}

            <DetailsLabel>{t("InheritedChecklistAssignmentsThatWillBeLost")}</DetailsLabel>
            {candidateNodeInheritedChecklistAssignmentResponseData!.length ? (
                candidateNodeInheritedChecklistAssignmentResponseData!.map((x, index) =>
                    x.isInherited ? <DetailsValue key={index}>{x.name}</DetailsValue> : <></>
                )
            ) : (
                <NoDataStateDiv>{t("None", { keyPrefix: Common })}</NoDataStateDiv>
            )}
        </>
    );

    const buildDestinationNodeDetailsContent = (): ReactElement<HTMLDivElement> => (
        <>
            {buildNodeDetailsContent(destinationNodeDetailsResponseData!)}

            <DetailsLabel>{t("ChecklistAssignmentsToBeInherited")}</DetailsLabel>
            {destinationNodeInheritedChecklistAssignmentResponseData!.length ? (
                destinationNodeInheritedChecklistAssignmentResponseData!.map((x, index) => (
                    <DetailsValue key={index}>{x.name}</DetailsValue>
                ))
            ) : (
                <NoDataStateDiv>{t("None", { keyPrefix: Common })}</NoDataStateDiv>
            )}
        </>
    );

    const buildNodeDetailsContent = (
        nodeDescriptionDetailsDto: NodeDescriptionDetailsDto
    ): ReactElement<HTMLDivElement> => (
        <>
            <DetailsLabel>{t("NodeName")}</DetailsLabel>
            <DetailsValue>{nodeDescriptionDetailsDto.shortDescriptionWithParent}</DetailsValue>

            <DetailsLabel>{t("NodeDescription")}</DetailsLabel>
            <DetailsValue>{nodeDescriptionDetailsDto.longDescription}</DetailsValue>
        </>
    );
    // TODO: Investigate this eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const buildChecklistAssignmentContent = (
        inheritedChecklistAssignmentDtos: InheritedChecklistAssignmentDto[],
        dataView: ReactElement<HTMLDivElement>
    ): void => {
        inheritedChecklistAssignmentDtos.length ? (
            dataView
        ) : (
            <NoDataStateDiv>{t("None", { keyPrefix: Common })}</NoDataStateDiv>
        );
    };

    return (
        <>
            <PageHeading>{t("MoveNode")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(getMoveNodeDetails) && (
                <ContentContainer>
                    <StyledContainerDiv>
                        <StyledItemDiv>
                            <TextTitledPanel title={t("MoveCandidateNodeDetails")}>
                                {buildCandidateNodeDetailsContent()}
                            </TextTitledPanel>
                        </StyledItemDiv>

                        <StyledIcon icon={faArrowRight} size={"3x"} />

                        <StyledItemDiv>
                            <TextTitledPanel title={t("MoveDestinationNodeDetails")}>
                                {buildDestinationNodeDetailsContent()}
                            </TextTitledPanel>
                        </StyledItemDiv>
                    </StyledContainerDiv>
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <ConfirmButton type={"button"} onClick={onMoveNodeConfirmed} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </ContentContainer>
            )}
        </>
    );
};

export default MoveNodeContainer;

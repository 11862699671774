import { HTTPError } from "ky";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import {
    CreateAndAssociateQuestionSetToChecklistEvent,
    CreateQuestionSetEvent,
} from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    calculateMinutes,
    DayOrdinalOptions,
    DaysOfTheWeekOptions,
    FrequencyOptions,
    NonWorkingDayRolloverOptions,
    PeriodTypeOptions,
} from "../../../core/utilities/QuestionSetValues";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { PeriodType } from "../../../domain/enums/PeriodType";
import { DailyScheduleOption } from "../../../domain/enums/questions/question-sets/DailyScheduleOptions";
import { DayOrdinal } from "../../../domain/enums/questions/question-sets/DayOrdinalNumber";
import { DaysOfWeek } from "../../../domain/enums/questions/question-sets/DaysOfTheWeek";
import { FrequencyType } from "../../../domain/enums/questions/question-sets/FrequencyType";
import { MonthlyScheduleOption } from "../../../domain/enums/questions/question-sets/MonthlyScheduleOption";
import { MonthOfTheYear } from "../../../domain/enums/questions/question-sets/MonthOfTheYear";
import { NonWorkingDayRolloverType } from "../../../domain/enums/questions/question-sets/NonWorkingDayRolloverType";
import { WeeklyScheduleOption } from "../../../domain/enums/questions/question-sets/WeeklyScheduleOption";
import { YearlyScheduleOption } from "../../../domain/enums/questions/question-sets/YearlyScheduleOption";
import { CreateQuestionSetSchedule } from "../../../domain/requests/question-set/create-question-set-schedule-request";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateAndAssociateQuestionSet } from "../../../domain/viewmodels/checklists/create-and-associate-question-set-viewmodel";
import { useCreateQuestionSet } from "../../../domain/viewmodels/question-set/create-question-set-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { AdhocHolidayRollOverSchedule } from "../../molecules/AdhocHolidayRollOverSchedule";
import { DailySchedule } from "../../molecules/DailySchedule";
import { FrequencyTypeSchedule } from "../../molecules/FrequencyTypeSchedule";
import { HolidayRollOverSchedule } from "../../molecules/HolidayRollOverSchedule";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { MonthlySchedule } from "../../molecules/MonthlySchedule";
import { OnceOffSchedule } from "../../molecules/OnceOffSchedule";
import { ComponentPanel, TextTitledPanel } from "../../molecules/SbPanel";
import { WeeklySchedule } from "../../molecules/WeeklySchedule";
import { YearlySchedule } from "../../molecules/YearlySchedule";

const StyledForm = styled(Form)`
    overflow: visible;
    position: relative;
`;

const StyledSubForm = styled(Form)`
    padding-left: ${(props) => props.theme.padding.xxxl};
    color: ${(props) => props.theme.palette.secondary};
    font-weight: 600;
    overflow: visible;
`;

const StyledDivSeparatorLine = styled.div`
    border-right: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    padding-right: ${(props) => props.theme.padding.lg};
    height: ${(props) => props.theme.dimensions.height.xxxl};
`;

const CreateQuestionSetContainer: React.FC = () => {
    const [selectedFrequencyOption, setSelectedFrequency] = useState("");
    const [selectedDailyScheduleDescription, setSelectedDailyScheduleDescription] = useState("");
    const [selectedMonthlyScheduleDescription, setSelectedMonthlyScheduleDescription] =
        useState("");
    const [selectedYearlyScheduleDescription, setSelectedYearlyScheduleDescription] = useState("");
    const [checkedMondayScheduleDescription, setcheckedMondayScheduleDescription] = useState(false);
    const [checkedTuesdayScheduleDescription, setCheckedTuesdayScheduleDescription] =
        useState(false);
    const [checkedWednesdayScheduleDescription, setCheckedWednesdayScheduleDescription] =
        useState(false);
    const [checkedThursdayScheduleDescription, setCheckedThursdayScheduleDescription] =
        useState(false);
    const [checkedFridayScheduleDescription, setCheckedFridayScheduleDescription] = useState(false);
    const [checkedSaturdayScheduleDescription, setCheckedSaturdayScheduleDescription] =
        useState(false);
    const [checkedSundayScheduleDescription, setCheckedSundayScheduleDescription] = useState(false);
    const [viewableFromNumber, setViewableFromNumber] = useState(1);
    const [viewableFromDropDown, setViewableFromDropDown] = useState("Hours");
    const [selectedLockoutOption, setSelectedLockoutOption] = useState(false);
    const [lockoutNumberAfterDue, setLockoutNumberAfterDue] = useState(1);
    const [lockoutOptionAfterDue, setLockoutOptionAfterDue] = useState("Hours");
    const [selectedQuestionSetSchedule, setSelectedQuestionSetSchedule] = useState(
        CreateQuestionSetSchedule()
    );
    const [arMonitoring, setARMonitoring] = useState(false);

    const menu = useMenu();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(urlSearchParams.get("checklistId"));

    const createQuestionSet = useCreateQuestionSet(selectedQuestionSetSchedule);
    const createAndAssociateQuestionSet = useCreateAndAssociateQuestionSet(
        selectedQuestionSetSchedule
    );

    useLoader(
        isMutationLoading(createQuestionSet) || isMutationLoading(createAndAssociateQuestionSet),
        CreateQuestionSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);
    }, []);

    useEffect(() => {
        if (arMonitoring) {
            handleFrequencyChange(FrequencyType.Adhoc.toString(), false);

            setViewableFromNumber(1);

            const arMonitoringPeriod = PeriodTypeOptions.find(
                (x) => x.value === PeriodType.Days
            )!.label;

            handleViewableFromDropDownChange({
                value: arMonitoringPeriod,
                label: arMonitoringPeriod,
            });

            selectedQuestionSetSchedule.viewableMinutesBeforeBaseStart = calculateMinutes(
                1,
                "Days"
            );
            setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
        } else {
            handleFrequencyChange("", false);
        }
    }, [arMonitoring]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (checklistId) {
            createAndAssociateQuestionSet.mutate(
                {
                    checklistId: checklistId,
                    formData: new FormData(event.currentTarget),
                },
                {
                    onSuccess: async (response: Response<number>) => {
                        trackAppInsightsEvent(
                            auth.email,
                            window.location.href,
                            CreateAndAssociateQuestionSetToChecklistEvent
                        );
                        queryClient.invalidateQueries(["getAssociatedQuestionSets"]);

                        const params = [
                            createNavigateSearchParameter("success", "true"),
                            createNavigateSearchParameter(
                                "messageKey",
                                "SuccessfullyAddedAndAssociatedQuestionSet"
                            ),
                        ];

                        navigateSearch(
                            `${getPath(AccordionTitles.QuestionSets)}/${response.data}`,
                            params
                        );
                    },
                    onError: (error: HTTPError) => {
                        trackAppInsightsException(
                            auth.email,
                            window.location.href,
                            CreateAndAssociateQuestionSetToChecklistEvent,
                            error
                        );
                        errorResponseToDisplayHandler(error);
                    },
                }
            );
        } else {
            createQuestionSet.mutate(
                {
                    formData: new FormData(event.currentTarget),
                },
                {
                    onSuccess: async (response: Response<number>) => {
                        trackAppInsightsEvent(
                            auth.email,
                            window.location.href,
                            CreateQuestionSetEvent
                        );
                        navigate(`${getPath(AccordionTitles.QuestionSets)}/${response.data}`);
                    },
                    onError: (error: HTTPError) => {
                        trackAppInsightsException(
                            auth.email,
                            window.location.href,
                            CreateQuestionSetEvent,
                            error
                        );
                        errorResponseToDisplayHandler(error);
                    },
                }
            );
        }
    };

    function setWeekDays(weeklyScheduleOption: WeeklyScheduleOption): void {
        const index =
            selectedQuestionSetSchedule.weeklyScheduleOptions.indexOf(weeklyScheduleOption);
        const hasOption =
            selectedQuestionSetSchedule.weeklyScheduleOptions.includes(weeklyScheduleOption);

        hasOption
            ? selectedQuestionSetSchedule.weeklyScheduleOptions.splice(index, 1)
            : selectedQuestionSetSchedule.weeklyScheduleOptions.push(weeklyScheduleOption);

        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    }

    const handleFrequencyChange = (name: string, _: boolean): void => {
        setSelectedFrequency(name);
        selectedQuestionSetSchedule.frequencyType =
            FrequencyOptions.find((x) => x.value.toString() == name)?.value ?? FrequencyType.None;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleDailyScheduleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        switch (event.target.name) {
            case "everyXDays":
                selectedQuestionSetSchedule.dailyScheduleOption = DailyScheduleOption.EveryXDay;
                selectedQuestionSetSchedule.dayOfWeek = DaysOfWeek.Any;
                break;
            case "everyWeekday":
                selectedQuestionSetSchedule.dailyScheduleOption = DailyScheduleOption.EveryWeekday;
                selectedQuestionSetSchedule.dayOfWeek = DaysOfWeek.Weekday;
                break;
            case "everyWeekday+Saturday":
                selectedQuestionSetSchedule.dailyScheduleOption =
                    DailyScheduleOption.EveryWeekdayPlusSaturday;
                selectedQuestionSetSchedule.dayOfWeek = DaysOfWeek.WeekdayInclSaturday;
                break;
        }
        setSelectedDailyScheduleDescription(event.target.name);
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleMonthlyScheduleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        switch (event.target.name) {
            case "dayXofEveryXMonths":
                selectedQuestionSetSchedule.monthlyScheduleOption =
                    MonthlyScheduleOption.DayXOfEveryXmonths;
                break;
            case "theXOfEveryXMonth":
                selectedQuestionSetSchedule.monthlyScheduleOption =
                    MonthlyScheduleOption.TheXXOfEveryXMonths;
                break;
        }
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
        setSelectedMonthlyScheduleDescription(event.target.name);
    };

    const handleYearlyScheduleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        switch (event.target.name) {
            case "onX":
                selectedQuestionSetSchedule.yearlyScheduleOption = YearlyScheduleOption.OnXX;
                break;
            case "onTheXOf":
                selectedQuestionSetSchedule.yearlyScheduleOption =
                    YearlyScheduleOption.OnTheXDayOfX;
                break;
        }
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
        setSelectedYearlyScheduleDescription(event.target.name);
    };

    const handleLockoutOption = (): void => {
        setSelectedLockoutOption(!selectedLockoutOption);
        selectedQuestionSetSchedule.isLockoutEnabled = !selectedLockoutOption;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleViewableFromNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setViewableFromNumber(event.target.valueAsNumber);
        selectedQuestionSetSchedule.viewableMinutesBeforeBaseStart = calculateMinutes(
            event.target.valueAsNumber,
            viewableFromDropDown
        );
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleViewableFromDropDownChange = (
        item: SingleValue<{ value: string; label: string }>
    ): void => {
        setViewableFromDropDown(item ? item.value : "Hours");
        selectedQuestionSetSchedule.viewableMinutesBeforeBaseStart = calculateMinutes(
            viewableFromNumber,
            item ? item.value : "Hours"
        );
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleBaseStartTimeOfDayChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.baseStartTimeOfDay = event.target.value;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleNumberOfDaysChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.numberOfDays = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleNumberOfWeeksChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.numberOfWeeks = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleDayOfTheMonthChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.dayOfMonth = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleMonthsOfTheYearIntervalChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        selectedQuestionSetSchedule.monthsOfTheYearInterval = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleDayOrdinalChange = (item: SingleValue<ReactSelectDto<DayOrdinal>>): void => {
        selectedQuestionSetSchedule.dayOrdinal =
            DayOrdinalOptions.find((x) => x.label == item?.label)?.value ?? DayOrdinal.None;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleDayOfTheWeekChange = (item: SingleValue<ReactSelectDto<DaysOfWeek>>): void => {
        selectedQuestionSetSchedule.dayOfWeek =
            DaysOfTheWeekOptions.find((x) => x.label == item?.label)?.value ?? DaysOfWeek.Any;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleWeeklyScheduleDescriptionChange = (name: string, _: boolean): void => {
        switch (name) {
            case "monday":
                setWeekDays(WeeklyScheduleOption.Monday);
                setcheckedMondayScheduleDescription(!checkedMondayScheduleDescription);
                break;

            case "tuesday":
                setWeekDays(WeeklyScheduleOption.Tuesday);
                setCheckedTuesdayScheduleDescription(!checkedTuesdayScheduleDescription);
                break;

            case "wednesday":
                setWeekDays(WeeklyScheduleOption.Wednesday);
                setCheckedWednesdayScheduleDescription(!checkedWednesdayScheduleDescription);
                break;

            case "thursday":
                setWeekDays(WeeklyScheduleOption.Thursday);
                setCheckedThursdayScheduleDescription(!checkedThursdayScheduleDescription);
                break;

            case "friday":
                setWeekDays(WeeklyScheduleOption.Friday);
                setCheckedFridayScheduleDescription(!checkedFridayScheduleDescription);
                break;

            case "saturday":
                setWeekDays(WeeklyScheduleOption.Saturday);
                setCheckedSaturdayScheduleDescription(!checkedSaturdayScheduleDescription);
                break;

            case "sunday":
                setWeekDays(WeeklyScheduleOption.Sunday);
                setCheckedSundayScheduleDescription(!checkedSundayScheduleDescription);
                break;
        }
    };

    const handleMonthOfTheYearChange = (
        item: SingleValue<ReactSelectDto<MonthOfTheYear>>
    ): void => {
        selectedQuestionSetSchedule.monthOfTheYear =
            MonthOfTheYear[item?.label as keyof typeof MonthOfTheYear];
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleYearIntervalChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.yearInterval = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.year = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleMaxOccurrencesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        selectedQuestionSetSchedule.maxOccurrences = event.target.valueAsNumber;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleHolidayRollOverChange = (
        item: SingleValue<{ value: string; label: string }>
    ): void => {
        selectedQuestionSetSchedule.nonWorkingDayRolloverType =
            NonWorkingDayRolloverOptions.find((x) => x.label == item?.label)?.value ??
            NonWorkingDayRolloverType.None;
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleLockoutNumberAfterDue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLockoutNumberAfterDue(event.target.valueAsNumber);
        selectedQuestionSetSchedule.lockoutMinutesAfterDue = calculateMinutes(
            event.target.valueAsNumber,
            lockoutOptionAfterDue
        );
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleLockoutOptionAfterDue = (
        item: SingleValue<{ value: string; label: string }>
    ): void => {
        setLockoutOptionAfterDue(item ? item.value : "Hours");
        selectedQuestionSetSchedule.lockoutMinutesAfterDue = calculateMinutes(
            lockoutNumberAfterDue,
            item ? item.value : "Hours"
        );
        setSelectedQuestionSetSchedule(selectedQuestionSetSchedule);
    };

    const handleARMonitoringChange = (_: string, checked: boolean): void =>
        setARMonitoring(checked);

    const arMonitoringCheckValues = [
        {
            name: "arMonitoring",
            label: "",
            onChangeHandler: handleARMonitoringChange,
        },
    ];

    return (
        <>
            <PageHeading>{t("CreateQuestionSet")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextQuestionSetCreate")}</PageSubHeading>
            <SectionVerticalSpace />
            <StyledForm onSubmit={handleSubmit}>
                <ComponentPanel>
                    <TextTitledPanel title={t("QuestionSetDetails")}>
                        <SbFormTextFieldGroup
                            name="name"
                            label={t("Name", { keyPrefix: Common })}
                            maxLength={100}
                            type="text"
                            required
                        />

                        <SbFormTextFieldGroup
                            name="description"
                            label={t("Description", { keyPrefix: Common })}
                            maxLength={250}
                            type="text"
                        />

                        <SbFormTextFieldGroup
                            maxLength={100}
                            name="process"
                            label={t("Process")}
                            type="text"
                        />

                        <SbFormCheckFieldGroup
                            fieldLabel={t("ARMonitoring", { keyPrefix: Common })}
                            type={"checkbox"}
                            values={arMonitoringCheckValues}
                        />
                    </TextTitledPanel>
                    <SectionVerticalSpace />

                    <TextTitledPanel title={t("QuestionSetAssignmentSchedule")}>
                        <ComponentPanel>
                            <StyledSubForm>
                                <Row>
                                    {t("FrequencyType")}
                                    <Col sm={2}>
                                        <StyledDivSeparatorLine>
                                            <FrequencyTypeSchedule
                                                handleFrequencyChange={handleFrequencyChange}
                                                selectedFrequencyOption={selectedFrequencyOption}
                                                useAutoColumnWidth
                                                disabled={arMonitoring}
                                            />
                                        </StyledDivSeparatorLine>
                                    </Col>
                                    <Col>
                                        {selectedFrequencyOption ===
                                            FrequencyType.Daily.toString() && (
                                            <DailySchedule
                                                handleDailyScheduleDescriptionChange={
                                                    handleDailyScheduleDescriptionChange
                                                }
                                                selectedDailyScheduleDescription={
                                                    selectedDailyScheduleDescription
                                                }
                                                handleNumberOfDaysChange={handleNumberOfDaysChange}
                                            ></DailySchedule>
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Weekly.toString() && (
                                            <WeeklySchedule
                                                handleNumberOfWeeksChange={
                                                    handleNumberOfWeeksChange
                                                }
                                                handleWeeklyScheduleDescriptionChange={
                                                    handleWeeklyScheduleDescriptionChange
                                                }
                                                checkedMondayScheduleDescription={
                                                    checkedMondayScheduleDescription
                                                }
                                                checkedTuesdayScheduleDescription={
                                                    checkedTuesdayScheduleDescription
                                                }
                                                checkedWednesdayScheduleDescription={
                                                    checkedWednesdayScheduleDescription
                                                }
                                                checkedThursdayScheduleDescription={
                                                    checkedThursdayScheduleDescription
                                                }
                                                checkedFridayScheduleDescription={
                                                    checkedFridayScheduleDescription
                                                }
                                                checkedSaturdayScheduleDescription={
                                                    checkedSaturdayScheduleDescription
                                                }
                                                checkedSundayScheduleDescription={
                                                    checkedSundayScheduleDescription
                                                }
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Monthly.toString() && (
                                            <MonthlySchedule
                                                handleMonthlyScheduleDescriptionChange={
                                                    handleMonthlyScheduleDescriptionChange
                                                }
                                                selectedMonthlyScheduleDescription={
                                                    selectedMonthlyScheduleDescription
                                                }
                                                handleDayOfTheMonthChange={
                                                    handleDayOfTheMonthChange
                                                }
                                                handleMonthsOfTheYearIntervalChange={
                                                    handleMonthsOfTheYearIntervalChange
                                                }
                                                handleDayOrdinalChange={handleDayOrdinalChange}
                                                handleDayOfTheWeekChange={handleDayOfTheWeekChange}
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Yearly.toString() && (
                                            <YearlySchedule
                                                selectedYearlyScheduleDescription={
                                                    selectedYearlyScheduleDescription
                                                }
                                                handleYearlyScheduleDescriptionChange={
                                                    handleYearlyScheduleDescriptionChange
                                                }
                                                handleDayOfTheMonthChange={
                                                    handleDayOfTheMonthChange
                                                }
                                                handleDayOrdinalChange={handleDayOrdinalChange}
                                                handleDaysOfTheWeekChange={handleDayOfTheWeekChange}
                                                handleMonthOfTheYearChange={
                                                    handleMonthOfTheYearChange
                                                }
                                                handleYearIntervalChange={handleYearIntervalChange}
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.OnceOff.toString() && (
                                            <OnceOffSchedule
                                                handleDayOfTheMonthChange={
                                                    handleDayOfTheMonthChange
                                                }
                                                handleMonthOfTheYearChange={
                                                    handleMonthOfTheYearChange
                                                }
                                                handleYearChange={handleYearChange}
                                            />
                                        )}
                                        {selectedFrequencyOption ===
                                            FrequencyType.Adhoc.toString() && null}
                                    </Col>
                                </Row>
                            </StyledSubForm>
                        </ComponentPanel>

                        <SectionVerticalSpace />

                        {selectedFrequencyOption === FrequencyType.Adhoc.toString() ? (
                            <AdhocHolidayRollOverSchedule
                                handleLockoutOption={handleLockoutOption}
                                selectedLockoutOption={selectedLockoutOption}
                                handleLockoutNumberAfterDue={handleLockoutNumberAfterDue}
                                handleLockoutOptionAfterDue={handleLockoutOptionAfterDue}
                                viewableFromNumber={1}
                                handleViewableFromDropDownChange={handleViewableFromDropDownChange}
                                handleViewableFromNumberChange={handleViewableFromNumberChange}
                                arMonitoring={arMonitoring}
                            />
                        ) : (
                            <HolidayRollOverSchedule
                                handleBaseStartTimeOfDayChange={handleBaseStartTimeOfDayChange}
                                baseStartTimeOfDay={"00:00"}
                                lockoutNumber={1}
                                viewableFromNumber={1}
                                handleLockoutOption={handleLockoutOption}
                                handleViewableFromDropDownChange={handleViewableFromDropDownChange}
                                handleViewableFromNumberChange={handleViewableFromNumberChange}
                                selectedLockoutOption={selectedLockoutOption}
                                handleMaxOccurrencesChange={handleMaxOccurrencesChange}
                                handleHolidayRollOverChange={handleHolidayRollOverChange}
                                handleLockoutNumberAfterDue={handleLockoutNumberAfterDue}
                                handleLockoutOptionAfterDue={handleLockoutOptionAfterDue}
                            />
                        )}
                    </TextTitledPanel>
                    <SectionVerticalSpace />
                    <EndAlignedDiv>
                        <SaveButton type="submit" />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </ComponentPanel>
            </StyledForm>
        </>
    );
};

export default CreateQuestionSetContainer;

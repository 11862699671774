import jwtDecode from "jwt-decode";
import React, { useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import client from "../../data/http-client";
import { hasRoleTypeInGroup, Role, UserRoleGroup } from "../../domain/enums/Roles";
import { WebTokenType } from "../../domain/responses/account/token-response";
import { useUrl } from "./url-context";
import { useWizardNotification } from "./wizard-notification-context";

export interface AuthContextType {
    userName: string;
    email: string;
    userRoles: Role[];
    isAdmin: boolean;
    isImpersonating: boolean;
    tenantId: number | null;
    userId: number | null;
    tenant: string;
    isCookiesAccepted: boolean;
    logOut: () => void;
    updateUserName: (_: string) => void;
    updateEmail: (_: string) => void;
    updateUserRoles: (_: Role[]) => void;
    updateTenantId: (_: number) => void;
    updateUserId: (_: number) => void;
    updateIsImpersonating: (_: boolean) => void;
    updateIsCookiesAccepted: (_: boolean) => void;
}

const AuthContext = React.createContext<AuthContextType>({
    userName: "",
    email: "",
    userRoles: [],
    isAdmin: false,
    isImpersonating: false,
    tenantId: null,
    userId: null,
    tenant: "",
    isCookiesAccepted: false,
    logOut: () => {},
    updateUserName: (_: string) => {},
    updateEmail: (_: string) => {},
    updateUserRoles: (_: Role[]) => {},
    updateTenantId: (_: number) => {},
    updateUserId: (_: number) => {},
    updateIsImpersonating: (_: boolean) => {},
    updateIsCookiesAccepted: (_: boolean) => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const url = useUrl(); // Temporary while Login is not using a View Model
    const wizardNotification = useWizardNotification();
    const navigate = useNavigate();

    const [tenant, setTenant] = useState("");
    const [userName, setUserName] = useState(""); // TODO: instead of having separate states for user, userRoles and userId have them combined in a state object
    const [userRoles, setUserRoles] = useState<Role[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
    const [tenantId, setTenantId] = useState<number | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [email, setEmail] = useState("");
    const [isCookiesAccepted, setIsCookiesAccepted] = useState<boolean>(false);

    const logOut = (): void => {
        // TODO: Clean up the revoke tokens call to not be a direct call here
        client.post(
            `${isImpersonating ? url.originalBaseUrl : url.baseUrl}auth/revoke-tokens/${userId}`
        );
        setTenant("");
        setUserName("");
        setTenantId(null);
        setUserId(null);
        setEmail("");
        setIsImpersonating(false);

        localStorage.clear();

        if (isCookiesAccepted) {
            localStorage.setItem("isCookiesAccepted", "true");
        }

        sessionStorage.clear();

        wizardNotification.onWizardCardsFetch([]);

        url.updateBaseUrl(url.originalBaseUrl);

        navigate("/logout");
    };

    const updateUserName = (name: string): void => setUserName(name);

    const updateEmail = (email: string): void => setEmail(email);

    const updateUserRoles = (roles: Role[]): void => {
        setUserRoles(roles);

        setIsAdmin(hasRoleTypeInGroup(roles, UserRoleGroup.SystemAdminRoles));
    };

    const updateTenantId = (tenantId: number): void => setTenantId(tenantId);

    const updateUserId = (userId: number): void => setUserId(userId);

    const updateIsImpersonating = (isImpersonating: boolean): void =>
        setIsImpersonating(isImpersonating);

    const updateIsCookiesAccepted = (isCookiesAccepted: boolean): void =>
        setIsCookiesAccepted(isCookiesAccepted);

    const value = {
        userName,
        email,
        userRoles,
        isAdmin,
        isImpersonating,
        tenantId,
        userId,
        tenant,
        logOut,
        updateUserName,
        updateEmail,
        updateUserRoles,
        updateTenantId,
        updateUserId,
        updateIsImpersonating,
        updateIsCookiesAccepted,
        isCookiesAccepted,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
    return useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
    const auth = useAuth();
    const location = useLocation();

    localStorage.setItem("lastUsedUrl", location.pathname);

    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
        const parsedRefreshToken = JSON.parse(refreshToken);

        const decodedToken = jwtDecode<WebTokenType>(parsedRefreshToken.refreshToken);
        // https://stackoverflow.com/a/24170950
        const expirationDate = new Date(decodedToken.exp * 1000);
        const current = new Date();

        if (expirationDate < current) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");

            window.location.reload(); // TODO: Find better way to Log User Out
        }
    }

    if (!auth.userName) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}
